import React, {useContext, useEffect, useRef, useState} from 'react';
import block from 'bem-cn-lite';
import {Button, Checkbox, List, TextInput} from '@gravity-ui/uikit';
import {BasketButton} from './components';
import {useDebounce} from 'use-debounce';

import './SearchBar.scss';
import {SearchInputModel, SearchRequest, SuggestItem} from '@features/search/interfaces';

import {CApi as userGwApi} from '@services/api';
import {AppContext} from 'app/context';
import {getAdjustedPrice} from '@utils/getAdjustedPrice';

const b = block('search-bar');

export interface SearchBarProps {
    className?: string;
    searchText: SearchInputModel;
    searchBrand: string;
    useAnalogs: boolean;
    searchHistory: SearchRequest[];
    onSearchClick?: () => void;
    onInputChange?: (value: SearchInputModel) => void;
    onBrandSelected?: (brand: SuggestItem) => void;
    onUseAnalogsChanged?: (value: boolean) => void;
    onBrandSuggestionsChanged?: (suggestions: SuggestItem[]) => void;
    onSearchHistorySelected?: (searchHistory: SearchRequest) => void;
    size?: 'm' | 'l';
    onInputFocus?: () => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({
    onSearchClick,
    onInputChange,
    onBrandSelected,
    onUseAnalogsChanged,
    onBrandSuggestionsChanged,
    onSearchHistorySelected,
    searchText,
    useAnalogs,
    size,
    searchHistory,
}) => {
    const isInit = useRef(true);
    const [searchInputFocus, setSearchInputFocus] = useState(false);
    const [suggestItems, setSuggestItems] = useState<SuggestItem[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(true);
    const [showSearchHistory, setShowSearchHistory] = useState(false);

    const [debouncedSearchInputValue] = useDebounce(searchText, 300);
    const [debouncedSearchInputFocus] = useDebounce(searchInputFocus, 100);

    const [isInItem, setIsInItem] = useState(false);

    const { state } = useContext(AppContext);

    function cleanSearchValue(value: string) {
        return value.replace(
            /[^a-zA-Z0-9аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ]+/g,
            '',
        ).toUpperCase();
    }

    function onSearchInputChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
        isInit.current = false;
        if (onInputChange) {
            const cleanedValue = cleanSearchValue(event.target.value);
            onInputChange({
                originalValue: event.target.value,
                cleanedValue: cleanedValue,
                needToSuggestBrands: true,
            });
        }
    }

    function onSearchInputFocusChangedHandler(value: boolean) {
        console.log(`SearchBar::onSearchInputFocusChangedHandler locals: ${JSON.stringify({
            value: value,
            isInit: isInit.current,
            searchText: searchText
        })}`);

        if (isInit.current && value) {
            isInit.current = false;
            if (searchText.cleanedValue)
                if (onInputChange) {
                    onInputChange({
                        originalValue: searchText.originalValue,
                        cleanedValue: searchText.cleanedValue,
                        needToSuggestBrands: true,
                    });
                }
        }
        setSearchInputFocus(value);
    }

    function onUseAnalogsChangedHandler(value: boolean) {
        if (onUseAnalogsChanged) onUseAnalogsChanged(value);
    }

    function handleBrandSelected(brand: SuggestItem) {
        setShowSuggestions(false);
        setShowSearchHistory(false);
        if (onBrandSelected) onBrandSelected(brand);
    }

    function handleSearchHistorySelected(searchHistory: SearchRequest) {
        setShowSuggestions(false);
        setShowSearchHistory(false);
        isInit.current = true;
        if (onSearchHistorySelected) onSearchHistorySelected(searchHistory);
    }

    function handleSearchClick() {
        setShowSuggestions(false);
        setShowSearchHistory(false);
        if (onSearchClick) onSearchClick();
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') handleSearchClick();
    }

    function handleSuggestionItemMouseEnter() {
        setIsInItem(true);
    }

    function handleSuggestionItemMouseLeave() {
        setIsInItem(false);
    }

    useEffect(() => {
        let ignore = false;

        if (isInit.current)
            return () => {
                ignore = true;
            };

        if (debouncedSearchInputValue?.cleanedValue) {
            if (!debouncedSearchInputValue.needToSuggestBrands)
                return () => {
                    ignore = true;
                };

            userGwApi()
                .suggestBrand.suggestBrandList({
                    article: debouncedSearchInputValue.cleanedValue,
                })
                .then((r) => {
                    if (!ignore) {
                        setSuggestItems(r.data.items as SuggestItem[]);
                        setShowSuggestions(r.data.items.length > 0);
                        setShowSearchHistory(false);
                        if (onBrandSuggestionsChanged) onBrandSuggestionsChanged(r.data.items as SuggestItem[]);
                    }
                })
                .catch((err) => {
                    // eslint-disable-next-line no-console
                    console.log(err);
                });
        } else {
            setSuggestItems([]);
            setShowSuggestions(false);
            setShowSearchHistory(searchHistory.length > 0);
            if (onBrandSuggestionsChanged) onBrandSuggestionsChanged([]);
        }

        return () => {
            ignore = true;
        };

    }, [debouncedSearchInputValue]);

    // show suggestions
    useEffect(() => {
        if (!searchInputFocus) return;
        const showSuggestions = searchInputFocus && searchText.originalValue.length > 0 && suggestItems.length > 0;
        setShowSuggestions(showSuggestions);
        const showSearchHistory = searchInputFocus && searchText.originalValue.length === 0 && searchHistory.length > 0;
        setShowSearchHistory(!showSuggestions && showSearchHistory);
    }, [searchInputFocus]);

    // hide suggestions
    useEffect(() => {
        if (debouncedSearchInputFocus || isInItem) return;
        setShowSuggestions(false);
        setShowSearchHistory(false);
    }, [debouncedSearchInputFocus, isInItem]);

    // Получаем количество элементов в корзине
    const basketItemCount = state.counters['basketItems'] || 0;
    const totalAmount = getAdjustedPrice(state.basket.summary.totalAmount, state.priceLevelSettings, 'search') || 0;

    return (
        <div className={b() + ' ' + size}>
            <div className={b('search-fields')}>
                <div className={b('search-input-group')}>
                    <div className={b('search-input-group-container')}>
                        <TextInput
                            size="xl"
                            view="normal"
                            placeholder="Поиск по артикулу, VIN"
                            className={b('search-input')}
                            value={searchText.originalValue}
                            onChange={onSearchInputChangeHandler}
                            onFocus={() => onSearchInputFocusChangedHandler(true)}
                            onBlur={() => onSearchInputFocusChangedHandler(false)}
                            onKeyDown={(e) => handleKeyDown(e)}
                        ></TextInput>
                        <Checkbox
                            className={b('search-use-analogs')}
                            size="l"
                            checked={useAnalogs}
                            onChange={(x) => onUseAnalogsChangedHandler(x.target.checked)}
                        >Аналоги</Checkbox>
                    </div>
                    {showSuggestions ? (
                        <List
                            items={suggestItems}
                            className={b('search-suggestions')}
                            itemHeight={34}
                            filterable={true}
                            itemsHeight={
                                () => {
                                    const heigthItems = 34 * suggestItems.length + 16;
                                    const heightScreen = window.innerHeight - 280; // TODO Вынести в константы
                                    return heigthItems < heightScreen ? heigthItems : heightScreen;
                                }
                            }
                            onItemClick={handleBrandSelected}
                            renderItem={(item) => {
                                return (
                                    <div
                                        onMouseEnter={() => handleSuggestionItemMouseEnter()}
                                        onMouseLeave={() => handleSuggestionItemMouseLeave()}
                                        className={b('search-suggestions-inner-item')}
                                    >
                                        {item.name}
                                    </div>
                                );
                            }}
                            itemsClassName={b('search-suggestions-items')}
                            itemClassName={b('search-suggestions-item')}
                            filterClassName={b('search-suggestions-filter')}
                            filterPlaceholder="Производители:"
                        ></List>
                    ) : (
                        <></>
                    )}
                    {showSearchHistory ? (
                        <List
                            items={searchHistory}
                            className={b('search-suggestions')}
                            itemHeight={34}
                            filterable={true}
                            itemsHeight={34 * searchHistory.length + 16}
                            onItemClick={handleSearchHistorySelected}
                            renderItem={(item) => {
                                return (
                                    <div
                                        onMouseEnter={() => handleSuggestionItemMouseEnter()}
                                        onMouseLeave={() => handleSuggestionItemMouseLeave()}
                                        className={b('search-suggestions-inner-item')}
                                    >
                                        {item.article + ' (' + item.brand + ')'}
                                    </div>
                                );
                            }}
                            itemsClassName={b('search-suggestions-items')}
                            itemClassName={b('search-suggestions-item')}
                            filterClassName={b('search-suggestions-filter')}
                            filterPlaceholder="Последние запросы:"
                        ></List>
                    ) : (
                        <></>
                    )}
                </div>
                <Button size="xl" view="action" onClick={() => handleSearchClick()}>
                    Искать
                </Button>
                <BasketButton
                    totalItems={basketItemCount}
                    totalAmount={totalAmount}
                />
            </div>
        </div>
    );
};
