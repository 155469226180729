import React, {useState} from 'react';
import block from 'bem-cn-lite';
import EnginePng from '@assets/engine.png';
import LogoPng from '@assets/logo.png';
import BannedPng from '@assets/banner.png';
import {ContactForm} from './components/ContactForm';
import {Button} from '@components/shared/button';
import {Outlet, useNavigate} from 'react-router-dom';
import {FooterBlock} from '@components';

import './IndexPage.scss';

const b = block('index-page');

export const IndexPage: React.FC = () => {
    const navigateTo = useNavigate();
    const [showForm, setShowForm] = useState(false);

    return (
        <div className={b()}>
            <header className={b('header')}>
                <img src={LogoPng} alt="Авто-Транзит" />
                <Button size={'xl'} onClick={() => setShowForm(true)} design="gray">
                    Связаться
                </Button>
            </header>
            <section className={b('content')}>
                <p>
                    Автозапчасти оптом<br />
                    напрямую<br />
                    от поставщиков
                </p>
                <Button className={b('btn-login')}
                    size="xl"
                    onClick={() => navigateTo('/login')}
                >
                    Войти в Личный кабинет
                </Button>
                <img src={EnginePng} width={441} alt={'engine'} />
            </section>
            <section className={b('banner')}>
                <img src={BannedPng} alt={'banner'} />
            </section>
            <FooterBlock />
            <Outlet />
            <ContactForm open={showForm} setOpen={setShowForm} />
        </div>
    );
};
