import React, {ChangeEvent} from 'react';
import block from 'bem-cn-lite';
import {TrashBin} from '@gravity-ui/icons';
import {Button, TextInput} from '@gravity-ui/uikit';
import {FilterRangeModel} from '@features/search/interfaces';
import './RangeGroup.scss';
import {formatCurrencyRub} from '@utils';

const b = block('range-group');

export interface RangeGroupProps {
    title: string;
    range?: FilterRangeModel;
    onRangeChange?: (range: FilterRangeModel) => void;
    isCurrency?: boolean; // TODO Обобщить: передать функцию для форматирования
}

export const RangeGroup: React.FC<RangeGroupProps> = ({title, range, onRangeChange, isCurrency}) => {
    // TODO Вынести в общие utils (+OrdersFilters component)
    const formattedNumber = new Intl.NumberFormat('ru-RU');

    function handle(rangeParam: FilterRangeModel) {
        if (onRangeChange) onRangeChange(rangeParam);
    }

    function getValue(e: ChangeEvent<HTMLInputElement>) {
        let value: number | undefined = parseInt(e.target.value.replace(/\s+/g, ''), 10);
        if (Number.isNaN(value)) value = undefined;
        return value;
    }

    const getPlaceholderText = (value: number | undefined, isCurrency?: boolean): string => {
        if (value === null || value === undefined) return '';
        if (isCurrency)
            return formatCurrencyRub(value);
        return formattedNumber.format(value);
    };

    return (
        <div className={b()}>
            <div className={b('header')}>
                <div className={b('title')}>{title}</div>
                <Button
                    className={b('delete-button')}
                    size="xs"
                    onClick={() => handle({...range, from: undefined, to: undefined})}
                >
                    <TrashBin></TrashBin>
                </Button>
            </div>
            <div className={b('values')}>
                <TextInput
                    className={b('value')}
                    value={
                        range?.from !== undefined ? formattedNumber.format(range.from).toString() : ''
                    }
                    placeholder={'от\xa0' + getPlaceholderText(range?.min, isCurrency)}
                    onChange={(e) =>
                        handle({...range, from: getValue(e)})
                    }
                ></TextInput>
                <TextInput
                    className={b('value')}
                    value={
                        range?.to !== undefined ? formattedNumber.format(range.to).toString() : ''
                    }
                    placeholder={'до\xa0' + getPlaceholderText(range?.max, isCurrency)}
                    onChange={(e) =>
                        handle({...range, to: getValue(e)})
                    }
                />
            </div>
        </div>
    );
};
