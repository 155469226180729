import React, {SyntheticEvent, useEffect, useState, useContext} from 'react';
import block from 'bem-cn-lite';
import {Text, Icon, Tooltip} from '@gravity-ui/uikit';
import {Copy, Envelope} from '@gravity-ui/icons';
import Whatsapp from '@assets/whatsapp-icon.svg';
import {ProfileContext} from 'app/Providers/ProfileProvider/ProfileProvider';
import {TooltipCopy} from '@components';
import './ManagerInfo.scss';

const b = block('manager-info');

export const ManagerInfo: React.FC = () => {
    const {stateProfile: {contacts: {staff: {manager, mop, supervisor}}}, setStateProfile} = useContext(ProfileContext);

    return (
        <div className={b()}>
            <Text variant='header-1'>Менеджеры</Text>
            <div className={b('manager', 'border')}>
                <Text variant='subheader-2'>Ваш менеджер:</Text>
                <Text className={b('firstname')} variant='body-2'>{manager.name}</Text>
                <TooltipCopy copyText={manager.email}>
                    <Icon className={b('icon')} data={Envelope} />
                    <Text variant='body-1'>{manager.email}</Text>
                </TooltipCopy>
                <div className={b('content')}>
                    <TooltipCopy copyText={manager.phone}>
                        <Icon className={b('icon')} data={Copy} />
                        <Text variant='body-1'>{manager.phone}</Text>
                    </TooltipCopy>
                    <Tooltip
                        content={
                            <Text className={b('whatsapp-tooltip-content')}>
                                Откроется Whatsapp в браузере
                            </Text>
                        }
                        placement="top-end"
                        contentClassName={b('whatsapp-tooltip')}
                        className={b('whatsapp-tooltip-container')}
                        openDelay={0}
                        closeDelay={0}
                        disabled={false}
                    >
                        <a href={`https://web.whatsapp.com/send?phone=${manager.phone}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={b('link')}>
                            <img src={Whatsapp} alt="Whatsapp" />
                        </a>
                    </Tooltip>
                </div>
            </div>
            <div className={b('manager', 'border')}>
                <Text variant='subheader-2'>Специалист клиентской поддержки:</Text>
                <Text className={b('firstname')} variant='body-2'>{mop.name}</Text>
                <TooltipCopy copyText={mop.email}>
                    <Icon className={b('icon')} data={Envelope} />
                    <Text variant='body-1'>{mop.email}</Text>
                </TooltipCopy>
                <div className={b('content')}>
                    <TooltipCopy copyText={mop.phone}>
                        <Icon className={b('icon')} data={Copy} />
                        <Text variant='body-1'>{mop.phone}</Text>
                    </TooltipCopy>
                    <Tooltip
                        content={
                            <Text className={b('whatsapp-tooltip-content')}>
                                Откроется Whatsapp в браузере
                            </Text>
                        }
                        placement="top-end"
                        contentClassName={b('whatsapp-tooltip')}
                        className={b('whatsapp-tooltip-container')}
                        openDelay={0}
                        closeDelay={0}
                        disabled={false}
                    >
                        <a href={`https://web.whatsapp.com/send?phone=${mop.phone}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={b('link')}>
                            <img src={Whatsapp} alt="Whatsapp" />
                        </a>
                    </Tooltip>
                </div>
            </div>
            <div className={b('manager', 'border')}>
                <Text variant='subheader-2'>Руководитель отдела продаж:</Text>
                <Text className={b('firstname')} variant='body-2'>{supervisor.name}</Text>
                <TooltipCopy copyText={supervisor.email}>
                    <Icon className={b('icon')} data={Envelope} />
                    <Text variant='body-1'>{supervisor.email}</Text>
                </TooltipCopy>
                <div className={b('content')}>
                    <TooltipCopy copyText={supervisor.phone}>
                        <Icon className={b('icon')} data={Copy} />
                        <Text variant='body-1'>{supervisor.phone}</Text>
                    </TooltipCopy>
                    <Tooltip
                        content={
                            <Text className={b('whatsapp-tooltip-content')}>
                                Откроется Whatsapp в браузере
                            </Text>
                        }
                        placement="top-end"
                        contentClassName={b('whatsapp-tooltip')}
                        className={b('whatsapp-tooltip-container')}
                        openDelay={0}
                        closeDelay={0}
                        disabled={false}
                    >
                        <a href={`https://web.whatsapp.com/send?phone=${supervisor.phone}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={b('link')}>
                            <img src={Whatsapp} alt="Whatsapp" />
                        </a>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};
