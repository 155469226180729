import {FiltersModel} from '@features/orders/interfaces';

export interface OrdersFilters {
    filters: FiltersModel;
}

const ORDERS_FILTERS_KEY = 'ordersFilters';

class OrdersFiltersStorage {
    set = (filters: FiltersModel) =>
        localStorage.setItem(ORDERS_FILTERS_KEY, JSON.stringify(filters));

    get = (): FiltersModel | null => {
        const filters = localStorage.getItem(ORDERS_FILTERS_KEY);
        return filters ? JSON.parse(filters) : null;
    };

    clear = () => localStorage.removeItem(ORDERS_FILTERS_KEY);
}

export const ordersFiltersStorage = new OrdersFiltersStorage();
