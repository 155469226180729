import {useContext, useEffect, useRef, useState} from 'react';
import {AppContext} from 'app/context';
import {OrderSearch} from '@services/user-gw';
import {CApi as userGwApi} from '@services/api';
import {FiltersModel} from '@features/orders/interfaces/FiltersModel';
import {OrderItemModel} from '@features/orders/interfaces';

export const useOrders = () => {
    const {state, emit} = useContext(AppContext);
    const {items, filters: contextFilters} = state.orders;
    const itemsRef = useRef<OrderItemModel[]>(items);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFiltered, setIsFiltered] = useState(false);
    const [filteredItems, setFilteredItems] = useState<OrderItemModel[]>(items);
    const [filters, setFilters] = useState<FiltersModel>(contextFilters);

    const hasLoaded = useRef(false);

    useEffect(() => {
        if (hasLoaded.current) return;
        hasLoaded.current = true;

        const searchData: OrderSearch = {
            limit: 5000,
            offset: 0
        };

        userGwApi()
            .orderSearch.orderSearchCreate(searchData)
            .then((response) => {
                const updatedItems = response.data.items.map((x) => {
                    return {
                        key: `${x.refId}:${x.article}`, // TODO refId должен быть уникальным, но это не так. Договориться с бэком о ключе
                        article: x.article,
                        comment: x.comment,
                        articleName: x.articleName,
                        brandName: x.brandName,
                        createdAt: new Date(Date.parse(x.createdAt)),
                        flags: x.flags,
                        return: x.return,
                        deliveryDate: new Date(Date.parse(x.deliveryInfo?.expected.client.date)),
                        price: x.price,
                        quantity: x.quantity,
                        refId: x.refId,
                        status: {
                            ...x.status,
                            createdAt: (() => {
                                const date = new Date(Date.parse(x.status.createdAt));
                                date.setSeconds(0, 0);
                                return date;
                            })()
                        },
                        sum: x.sum
                    } as OrderItemModel;
                });

                itemsRef.current = updatedItems;

                setFilters((prev) => {
                    const brands = [...new Set(updatedItems.map((x) => x.brandName))].sort();
                    const articles = [...new Set(updatedItems.map((x) => x.article))].sort();
                    const refs = [...new Set(updatedItems.map((x) => x.refId))].sort();
                    const statusesMap = updatedItems.reduce(
                        (acc, cur) => acc.set(cur.status.id, cur.status.name),
                        new Map<string, string>()
                    );

                    const existingBrands = prev.brands.length > 0;
                    const existingArticles = prev.articles.length > 0;
                    const existingRefs = prev.refs.length > 0;
                    const existingStatuses = prev.statuses.length > 0;

                    return {
                        ...prev,
                        brands: existingBrands
                            ? prev.brands
                            : brands.map((xx) => ({
                                  value: xx,
                                  label: xx,
                                  isSelected: false
                              })),
                        articles: existingArticles
                            ? prev.articles
                            : articles.map((xx) => ({
                                  value: xx,
                                  label: xx,
                                  isSelected: false
                              })),
                        refs: existingRefs
                            ? prev.refs
                            : refs.map((xx) => ({
                                  value: xx,
                                  label: xx,
                                  isSelected: false
                              })),
                        statuses: existingStatuses
                            ? prev.statuses
                            : [...statusesMap].map(([key, value]) => ({
                                  value: key,
                                  label: value,
                                  isSelected: false
                              }))
                    };
                });

                emit('updateOrders', {
                    orders: {
                        items: updatedItems
                    }
                });
            })
            .catch((error) => {
                console.error('Ошибка при поиске заказов:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        if (isLoading) return;

        const f = filters;
        const newFilteredItems = itemsRef.current.filter((x) => {
            if (f.createdAtRange) {
                const {start, end} = f.createdAtRange;
                const fromCreatedAt = start ? start.toDate() : null;
                const toCreatedAt = end ? end.toDate() : null;

                let isInRange = true;

                if (fromCreatedAt && toCreatedAt) {
                    isInRange = x.createdAt >= fromCreatedAt && x.createdAt <= toCreatedAt;
                } else if (fromCreatedAt) {
                    isInRange = x.createdAt >= fromCreatedAt;
                } else if (toCreatedAt) {
                    isInRange = x.createdAt <= toCreatedAt;
                }

                if (!isInRange) return false;
            }

            if (f.deliveryDateRange) {
                const {start, end} = f.deliveryDateRange;
                const fromDeliveryDate = start ? start.toDate() : null;
                const toDeliveryDate = end ? end.toDate() : null;

                let isInRange = true;

                if (fromDeliveryDate && toDeliveryDate) {
                    isInRange =
                        x.deliveryDate >= fromDeliveryDate && x.deliveryDate <= toDeliveryDate;
                } else if (fromDeliveryDate) {
                    isInRange = x.deliveryDate >= fromDeliveryDate;
                } else if (toDeliveryDate) {
                    isInRange = x.deliveryDate <= toDeliveryDate;
                }

                if (!isInRange) return false;
            }

            const selectedStatuses = f.statuses.filter((x) => x.isSelected).map((x) => x.value);
            if (selectedStatuses.length > 0) {
                const isInState = selectedStatuses.includes(x.status.id);
                if (!isInState) return false;
            }

            const selectedRefs = f.refs.filter((x) => x.isSelected).map((x) => x.value);
            if (selectedRefs.length > 0) {
                const isInState = selectedRefs.includes(x.refId);
                if (!isInState) return false;
            }

            const selectedBrands = f.brands.filter((x) => x.isSelected).map((x) => x.value);
            if (selectedBrands.length > 0) {
                const isInState = selectedBrands.includes(x.brandName);
                if (!isInState) return false;
            }

            const selectedArticles = f.articles.filter((x) => x.isSelected).map((x) => x.value);
            if (selectedArticles.length > 0) {
                const isInState = selectedArticles.includes(x.article);
                if (!isInState) return false;
            }

            if (f.price.from) {
                const isLesser = x.price < f.price.from;
                if (isLesser) return false;
            }

            if (f.price.to) {
                const isGreater = x.price > f.price.to;
                if (isGreater) return false;
            }

            if (f.sum.from) {
                const isLesser = x.sum < f.sum.from;
                if (isLesser) return false;
            }

            if (f.sum.to) {
                const isGreater = x.sum > f.sum.to;
                if (isGreater) return false;
            }

            if (f.onlyWithComments) {
                const hasComments = !!x.comment;
                if (!hasComments) return false;
            }

            return true;
        });

        setFilteredItems(newFilteredItems);
        setIsFiltered(true);

        emit('updateOrders', {
            orders: {
                items: newFilteredItems
            }
        });
    }, [filters, isLoading]);

    return {
        originalItems: itemsRef.current,
        items: filteredItems,
        filters,
        setFilters,
        isLoading,
        isFiltered
    };
};
