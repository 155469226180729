import {useContext} from 'react';
import block from 'bem-cn-lite';
import {ToasterComponent} from '@gravity-ui/uikit';
import {ToastVisibilityContext} from './ToastVisibilityContext';

import './CustomToaster.scss';

const b = block('custom-toaster');

export const CustomToaster: React.FC = () => {
    const {isToasterVisible, currentToastName} = useContext(ToastVisibilityContext);

    if (!isToasterVisible || currentToastName === 'basket-check-success') return null;

    return (
        <div className={b()}>
            <ToasterComponent />
        </div>
    );
};
