import React, {SyntheticEvent, useEffect, useState, useContext} from 'react';
import block from 'bem-cn-lite';
import {Text, Avatar, Icon, Alert} from '@gravity-ui/uikit';
import {Person, Envelope, Handset} from '@gravity-ui/icons';
import {ProfileContext} from 'app/Providers/ProfileProvider/ProfileProvider';
import './UserInfo.scss';

const b = block('user-info');

export const UserInfo: React.FC = () => {
    const {stateProfile: {user}} = useContext(ProfileContext);

    return (
        <div className={b()}>
            <Text variant='header-1'>Профиль</Text>
            <Avatar className={b('avatar')} size='xl' theme='brand' text={user.name} />
            <Text className={b('paragraph')} variant='body-2'>
                <Icon className={b('icon')} data={Person} />{user.name}</Text>
            <Text className={b('paragraph')} variant='body-2'>
                <Icon className={b('icon')} data={Envelope} />{user.email}</Text>
            <Text className={b('paragraph')} variant='body-2'>
                <Icon className={b('icon')} data={Handset} />{user.phone}</Text>
            <Alert theme="info" className={b('alert')} message='Для внесения изменений в профиль обратитесь к вашему менеджеру' />
        </div>
    );
};
