import block from 'bem-cn-lite';
import {Text} from '@gravity-ui/uikit';
import OrdersEmptyPng from '@assets/basket-empty.png';
import SearchNotFound from '@assets/search-not-found.svg';

import './OrdersEmpty.scss';

const b = block('orders-empty');

interface OrdersEmptyProps {
    isNoOrders: boolean;
}

export const OrdersEmpty: React.FC<OrdersEmptyProps> = ({isNoOrders}) => {
    return (
        <div className={b()}>
            {isNoOrders ? (
                <>
                    <img src={OrdersEmptyPng} alt="No orders" className={b('image')} />
                    <Text variant={'subheader-2'} className={b('text-no-orders')}>
                        У вас еще нет
                        <br />
                        оформленных заказов
                    </Text>
                </>
            ) : (
                <>
                    <img src={SearchNotFound} alt="Not found" className={b('image')} />
                    <div className={b('text-container')}>
                        <Text variant={'subheader-2'}>Ничего не нашлось.</Text>
                        <Text variant={'body-2'}>Попробуйте изменить настройки фильтров</Text>
                    </div>
                </>
            )}
        </div>
    );
};
