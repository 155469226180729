import React, {useContext, useState} from 'react';
import block from 'bem-cn-lite';
import './BasketSummary.scss';
import {Button, Text} from '@gravity-ui/uikit';
import {ArrowShapeTurnUpRight} from '@gravity-ui/icons';
import {formatCurrencyRub} from '@utils';
import {AppContext} from 'app/context';
import {useCheckBasket} from '@features/basket/hooks/useCheckBasket';
import {useCreateOrder} from '@features/basket/hooks/useCreateOrder';
import {CheckAvailableLoading} from '../CheckAvailableLoading';
import {getAdjustedPrice} from '@utils/getAdjustedPrice';

const b = block('basket-summary');

export const BasketSummary = () => {
    const {state} = useContext(AppContext);
    const {summary} = state.basket;

    const {
        checkBasket,
        isLoading: isCheckLoading,
        loadingText: checkLoadingText,
        setLoadingText
    } = useCheckBasket();
    const {
        createOrder,
        isLoading: isOrderLoading,
        loadingText: orderLoadingText
    } = useCreateOrder();
    const [isModalOpenCheckLoading, setIsModalOpenCheckLoading] = useState(false);

    const handleCreateOrderClick = async () => {
        setIsModalOpenCheckLoading(true);
        const isBasketValid = await checkBasket({showSuccessToast: false}); // Не показываем успешный тост проценки

        if (isBasketValid) {
            setLoadingText('Оформляем заказ...');
            await createOrder();
        }

        setIsModalOpenCheckLoading(false);
    };

    const currentLoadingText = checkLoadingText || orderLoadingText;
    const currentIsLoading = isCheckLoading || isOrderLoading;

    const adjustedTotalAmount = summary.totalAmount
        ? getAdjustedPrice(summary.totalAmount, state.priceLevelSettings, 'basket')
        : 0;

    return (
        <div className={b()}>
            <Text variant={'header-1'}>Сумма к заказу:</Text>
            <div className={b('amounts')}>
                <Text variant={'header-1'}>{formatCurrencyRub(adjustedTotalAmount)}</Text>
                <Text variant={'body-2'}>{summary.totalQuantity.toString()}&nbsp;шт.</Text>
            </div>
            <Button
                className={b('checkout')}
                size={'xl'}
                view={'action'}
                onClick={handleCreateOrderClick}
            >
                <div className={b('checkout-button')}>
                    <Text variant={'body-2'}>Оформить заказ</Text>
                    <ArrowShapeTurnUpRight className={b('checkout-icon')} />
                </div>
            </Button>

            <CheckAvailableLoading
                open={isModalOpenCheckLoading && currentIsLoading}
                text={currentLoadingText}
                onClose={() => setIsModalOpenCheckLoading(false)}
            />
        </div>
    );
};
