import React from 'react';
import block from 'bem-cn-lite';
import {Text, Tooltip} from '@gravity-ui/uikit';
import {ShieldCheck} from '@gravity-ui/icons';
import './OfficialDealerBadge.scss';

const b = block('official-dealer-badge');

export interface OfficialDealerBadgeProps {
    isOfficialDealer?: boolean;
}

export const OfficialDealerBadge: React.FC<OfficialDealerBadgeProps> = ({isOfficialDealer}) => {
    if (!isOfficialDealer) {
        return <div className={b()} />;
    }

    return (
        <Tooltip
            content={
                <Text className={b('official-dealer-badge-tooltip-content')}>
                    Офиц дилер
                </Text>
            }
            placement="top-end"
            contentClassName={b('official-dealer-badge-tooltip')}
            className={b('official-dealer-badge-tooltip-container')}
            openDelay={0}
            closeDelay={0}
            disabled={false}
        >
            <div className={b()}>
                <ShieldCheck />
            </div>
        </Tooltip>
    );
};