import React, {useState} from 'react';
import block from 'bem-cn-lite';
import {Popup} from '@gravity-ui/uikit';
import {CommentPlus, CommentDot} from '@gravity-ui/icons';
import {usePopoverAuxControls} from '@hooks';
import {CApi as userGwApi} from '@services/api';
import { BasketItemModel } from '@features/basket/interfaces';
import { TooltipButton } from '../TooltipButton/TooltipButton';
import { TooltipComment } from '../TooltipComment';

import '../AuxControls.scss';

const b = block('aux-controls');

interface CommentPopoverButtonProps {
    item: BasketItemModel;
}

export const CommentPopoverButton: React.FC<CommentPopoverButtonProps> = ({item}) => {
    const {open, tooltipVisible, buttonRef, toggleOpen, closePopover} = usePopoverAuxControls();

    const [savedComment, setSavedComment] = useState<string | null>(item.comment ?? null); // Сохраняем комментарий
    const [currentComment, setCurrentComment] = useState<string>(item.comment ?? ''); // Текущее состояние комментария для редактирования

    const handleSaveComment = (newComment: string) => {
        // Отправляем запрос для обновления комментария
        const updatedItem = {
            [item.id]: {
                itemsCnt: item.itemsCnt,
                comment: newComment  // Добавляем или обновляем комментарий
            }
        };

        userGwApi()
            .basket.basketPartialUpdate(updatedItem)
            .then(() => {
                setSavedComment(newComment); // Сохраняем комментарий
                setCurrentComment(newComment); // Обновляем состояние текущего комментария
                closePopover();
            })
            .catch((error) => {
                console.error("Ошибка при сохранении комментария:", error);
            });
    };

    const handleEditComment = () => {
        setCurrentComment(savedComment ?? ''); // Устанавливаем сохранённый комментарий в текстовое поле для редактирования
        toggleOpen();
    };

    // Устанавливаем стиль для кнопки, если комментарий сохранён
    const buttonStyle = savedComment ? { backgroundColor: 'rgba(255, 190, 92, 0.3)' } : {};

    return (
        <>
            <TooltipButton
                tooltipContent={savedComment ? "Редактировать комментарий" : "Добавить комментарий"}
                onClick={savedComment ? handleEditComment : toggleOpen}
                icon={savedComment ? <CommentDot /> : <CommentPlus />}
                className={b('button', b('comment'))}
                disabled={open}
                ref={buttonRef}
                style={buttonStyle}
            />

            <Popup anchorRef={buttonRef} open={open} placement="top-end" hasArrow={true} onClose={closePopover}>
                <div className={b('popup-content')}>
                    <TooltipComment
                        comment={currentComment}
                        onSave={handleSaveComment} 
                        onCancel={closePopover} 
                    />
                </div>
            </Popup>
        </>
    );
};