import {useEffect, useRef, useContext} from 'react';
import {Link} from 'react-router-dom';
import {useToaster, ButtonView, Button, Text} from '@gravity-ui/uikit';
import {CircleRuble, TriangleExclamation, Trolley} from '@gravity-ui/icons';
import {ToastVisibilityContext} from 'app/Providers/CustomToasterProvider/ToastVisibilityContext';
import {AppContext} from 'app/context';

export const useToast = () => {
    const {add, has, remove} = useToaster();
    const {isToasterVisible, setIsToasterVisible, setCurrentToastName, currentToastName} =
        useContext(ToastVisibilityContext);
    const toastNameRef = useRef<string | null>(null);
    const {state} = useContext(AppContext);
    const {summary} = state.basket;

    const showToast = (
        type: 'success' | 'danger' | 'warning',
        message: string,
        options?: {isPriceDecreasedOnly?: boolean}
    ) => {
        setIsToasterVisible(true);

        const {isPriceDecreasedOnly = false} = options || {};

        const additionalContent =
            (type === 'warning' || type === 'danger') && !isPriceDecreasedOnly
                ? 'Вы можете найти замену, удалить товары из корзины или продолжить оформление.'
                : null;

        const actions =
            (type === 'warning' || type === 'danger') && !isPriceDecreasedOnly
                ? [
                      {
                          label: 'Ок, понятно',
                          onClick: () => {
                              console.log('Action clicked');
                              setIsToasterVisible(false);
                          },
                          view: 'normal-contrast' as ButtonView,
                          removeAfterClick: true
                      }
                  ]
                : [];

        let toastName = `basket-check-${type}`;
        if (isPriceDecreasedOnly) {
            toastName = `basket-check-price-decreased`;
        }

        setCurrentToastName(toastName);
        toastNameRef.current = toastName;

        let theme = type;
        let toastParams: any = {
            name: toastName,
            title: message,
            content: additionalContent,
            theme,
            autoHiding: type === 'success' ? 5000 : false,
            actions,
            isClosable: true
        };

        if (isPriceDecreasedOnly) {
            // Для случая, когда только цены уменьшились
            toastParams.theme = 'success';
            toastParams.autoHiding = false;
            toastParams.actions = [
                {
                    label: 'Ок, понятно',
                    onClick: () => setIsToasterVisible(false),
                    view: 'normal-contrast' as ButtonView,
                    removeAfterClick: true
                }
            ];
            toastParams.renderIcon = () => <TriangleExclamation width={24} height={24} />;
        }

        add(toastParams);

        if (type === 'success' && !isPriceDecreasedOnly) {
            setTimeout(() => {
                setIsToasterVisible(false);
            }, 5000);
        }
    };

    const showAddToCartErrorToast = () => {
        setIsToasterVisible(true);
        setCurrentToastName('add_to_basket_error');

        const toastName = 'add_to_basket_error';
        toastNameRef.current = toastName;

        add({
            name: toastName,
            title: 'Произошла ошибка при добавлении товара в корзину',
            content: (
                <>
                    <Text variant="body-2">Попробуйте еще раз.</Text>
                    <Button
                        size="l"
                        view="normal-contrast"
                        onClick={() => {
                            remove(toastName);
                            setIsToasterVisible(false);
                        }}
                        style={{marginTop: '8px', width: '100%'}}
                    >
                        Ок, попробую еще раз
                    </Button>
                </>
            ),
            theme: 'danger',
            autoHiding: false,
            isClosable: true
        });
    };

    const showMakeOrderErrorToast = () => {
        setIsToasterVisible(true);
        setCurrentToastName('make_order_error');

        const toastName = 'make_order_error';
        toastNameRef.current = toastName;

        add({
            name: toastName,
            title: 'Нет выбранных товаров для оформления заказа',
            content: (
                <>
                    <Button
                        size="l"
                        view="normal-contrast"
                        onClick={() => {
                            remove(toastName);
                            setIsToasterVisible(false);
                        }}
                        style={{marginTop: '8px', width: '100%'}}
                    >
                        Ок, понятно
                    </Button>
                </>
            ),
            theme: 'danger',
            autoHiding: false,
            isClosable: true
        });
    };

    const showCreatedOrderToast = () => {
        setIsToasterVisible(true);
        setCurrentToastName('create-order');

        const toastName = 'create-order';
        toastNameRef.current = toastName;

        add({
            name: toastName,
            title: `Заказ успешно оформлен на сумму ${summary.totalAmount} ₽`,
            content: (
                <div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '8px',
                            borderRadius: '8px',
                            background: 'rgba(0, 0, 0, 0.05)'
                        }}
                    >
                        <Text variant="body-2" color="complementary">
                            Доступно для нового
                            <br />
                            заказа:
                        </Text>
                        <Text variant="body-2" color="primary">
                            5 000 000 ₽
                        </Text>
                    </div>

                    <div
                        style={{
                            marginTop: '8px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '8px'
                        }}
                    >
                        <Link to="/orders">
                            <Button
                                size="l"
                                view="normal-contrast"
                                onClick={() => {
                                    remove(toastName);
                                    setIsToasterVisible(false);
                                }}
                                style={{marginTop: '8px', width: '100%'}}
                            >
                                Перейти в заказы
                            </Button>
                        </Link>
                        <Button
                            size="l"
                            view="outlined"
                            onClick={() => {
                                remove(toastName);
                                setIsToasterVisible(false);
                            }}
                            style={{marginTop: '8px', width: '100%'}}
                        >
                            Ок, понятно
                        </Button>
                    </div>
                </div>
            ),
            theme: 'success',
            autoHiding: false,
            isClosable: true
        });
    };

    const showBlockOrderToast = () => {
        setIsToasterVisible(true);
        setCurrentToastName('block_order');

        const toastName = 'block_order';
        toastNameRef.current = toastName;

        add({
            name: toastName,
            title: 'Мы заблокировали возможность оформления заказа',
            content: (
                <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    <Text variant="body-2">Пожалуйста, обратитесь к вашему менеджеру</Text>

                    <div
                        style={{
                            marginTop: '8px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '8px'
                        }}
                    >
                        <Link to="/home">
                            <Button
                                size="l"
                                view="normal-contrast"
                                onClick={() => {
                                    remove(toastName);
                                    setIsToasterVisible(false);
                                }}
                                style={{width: '100%'}}
                            >
                                Показать контакты
                            </Button>
                        </Link>
                        <Button
                            size="l"
                            view="outlined"
                            onClick={() => {
                                remove(toastName);
                                setIsToasterVisible(false);
                            }}
                            style={{width: '100%'}}
                        >
                            Ок, понятно
                        </Button>
                    </div>
                </div>
            ),
            theme: 'danger',
            autoHiding: false,
            isClosable: true
        });
    };

    const showNotEnoughFinanceOrderToast = () => {
        setIsToasterVisible(true);
        setCurrentToastName('not_enough_finance_order');

        const toastName = 'not_enough_finance_order';
        toastNameRef.current = toastName;

        add({
            name: toastName,
            title: 'Недостаточно средств на счете. Оформление заказа невозможно.',
            content: (
                <div style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                    <Text variant="body-2">
                        Пожалуйста, пополните баланс или отправьте в заказ только часть товаров.
                    </Text>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px',
                            padding: '8px',
                            borderRadius: '8px',
                            background: 'rgba(0, 0, 0, 0.05)'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Text variant="body-2" color="primary">
                                Выбрано товаров:
                            </Text>
                            <Text variant="body-2" color="primary">
                                1 315 777 ₽
                            </Text>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Text variant="body-2" color="primary">
                                Баланс:
                            </Text>
                            <Text variant="body-2" color="primary">
                                300 000 ₽
                            </Text>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Text variant="body-2" color="primary">
                                Не хватает:
                            </Text>
                            <Text variant="body-2" color="danger">
                                1 015 777 ₽
                            </Text>
                        </div>
                    </div>

                    <div
                        style={{
                            marginTop: '8px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '8px'
                        }}
                    >
                        <Button
                            size="l"
                            view="normal-contrast"
                            onClick={() => {
                                remove(toastName);
                                setIsToasterVisible(false);
                            }}
                            style={{width: '100%'}}
                        >
                            Ок, понятно
                        </Button>

                        <Link to="/home">
                            <Button
                                size="l"
                                view="outlined"
                                onClick={() => {
                                    remove(toastName);
                                    setIsToasterVisible(false);
                                }}
                                style={{width: '100%'}}
                            >
                                Показать контакты
                            </Button>
                        </Link>
                    </div>
                </div>
            ),
            theme: 'danger',
            autoHiding: false,
            isClosable: true,
            renderIcon: () => <CircleRuble width={24} height={24} />
        });
    };

    const showNoticeCreatedOrderToast = () => {
        setIsToasterVisible(true);
        setCurrentToastName('notice_created_order');

        const toastName = 'notice_created_order';
        toastNameRef.current = toastName;

        add({
            name: toastName,
            title: 'Заказ будет принят в работу. Для его получения, пожалуйста, оплатите задолженность.',
            content: (
                <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '8px',
                            borderRadius: '8px',
                            background: 'rgba(0, 0, 0, 0.05)'
                        }}
                    >
                        <Text variant="body-2" color="primary">
                            Просроченная
                            <br />
                            задолженность:
                        </Text>
                        <Text variant="body-2" color="danger" style={{textAlign: 'right'}}>
                            -2 000 000 ₽
                            <br />5 дней
                        </Text>
                    </div>

                    <Button
                        size="l"
                        view="normal-contrast"
                        onClick={() => {
                            remove(toastName);
                            setIsToasterVisible(false);
                        }}
                        style={{marginTop: '8px', width: '100%'}}
                    >
                        Ок, понятно
                    </Button>
                </div>
            ),
            theme: 'warning',
            autoHiding: false,
            isClosable: true,
            renderIcon: () => <Trolley width={24} height={24} />
        });
    };

    // Отслеживаем удаление тоста
    useEffect(() => {
        if (!toastNameRef.current) return;

        const interval = setInterval(() => {
            if (!has(toastNameRef.current!)) {
                setIsToasterVisible(false);
                clearInterval(interval);
                toastNameRef.current = null; // Сбрасываем имя тоста после его закрытия
            }
        }, 100);

        return () => {
            clearInterval(interval);
        };
    }, [has, setIsToasterVisible, isToasterVisible]);

    // Для изменения позиции тостера при отсутсии измененений в товарах
    useEffect(() => {
        const root = document.documentElement;

        if (currentToastName === 'basket-check-success') {
            root.style.setProperty('--g-toaster-top', '180px');
        } else if (currentToastName) {
            root.style.setProperty('--g-toaster-top', '50%');
        }

        return () => {
            root.style.removeProperty('--g-toaster-top');
        };
    }, [currentToastName]);

    return {
        showToast,
        isToasterVisible,
        showAddToCartErrorToast,
        showCreatedOrderToast,
        showMakeOrderErrorToast,
        showBlockOrderToast,
        showNotEnoughFinanceOrderToast,
        showNoticeCreatedOrderToast
    };
};
