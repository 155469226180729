import {useRef, useState} from 'react';

export const usePopoverAuxControls = (initialOpen = false) => {
    const [open, setOpen] = useState(initialOpen);
    const [tooltipVisible, setTooltipVisible] = useState(true);
    const buttonRef = useRef(null);

    const toggleOpen = () => {
        setOpen(!open);
        setTooltipVisible(!open);
    };

    const closePopover = () => {
        setOpen(false);
        setTooltipVisible(true);
    };

    return {
        open,
        tooltipVisible,
        buttonRef,
        toggleOpen,
        closePopover,
    };
};
