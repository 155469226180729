import React, {useState} from 'react';
import block from 'bem-cn-lite';
import {Button, TextArea, Text} from '@gravity-ui/uikit';

import './TooltipComment.scss';

const b = block('tooltip-comment');

interface TooltipCommentProps {
    onSave: (comment: string) => void;
    onCancel: () => void;
    comment: string;
}

export const TooltipComment: React.FC<TooltipCommentProps> = ({
    onSave,
    onCancel,
    comment: initialComment,
}) => {
    const [comment, setComment] = useState(initialComment);

    const isInvalid = comment.length > 150;

    return (
        <div className={b()}>
            <div className={b('tooltip-comment-description')}>
                <TextArea
                    rows={4}
                    placeholder="Добавьте комментарий"
                    style={{width: '100%', maxWidth: '100%'}}
                    validationState={isInvalid ? 'invalid' : undefined}
                    controlProps={{style: {resize: "both"}}}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
                <Text
                    variant={'body-1'}
                    className={b('tooltip-comment-count')}
                    style={{color: isInvalid ? 'red' : 'inherit'}}
                >
                    {comment.length}/150
                </Text>
            </div>
            <div className={b('tooltip-comment-buttons')}>
                <Button onClick={onCancel} view="flat" className={b('tooltip-comment-button')}>
                    Отменить
                </Button>
                <Button
                    onClick={() => onSave(comment)}
                    disabled={isInvalid}
                    className={b('tooltip-comment-button')}
                >
                    Сохранить
                </Button>
            </div>
        </div>
    );
};
