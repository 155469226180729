import React, {useCallback, useContext, useState} from 'react';
import block from 'bem-cn-lite';
import './BasketHeaderControls.scss';
import {Checkbox, Text, Button} from '@gravity-ui/uikit';
import {ClockArrowRotateLeft, TrashBin} from '@gravity-ui/icons';
import {BasketTrashModal} from '../BasketTrashModal';
import {CheckAvailableLoading} from '../CheckAvailableLoading';
import {useBasket} from '@features/basket/hooks/useBasket';
import {AppContext} from 'app/context';
import {useCheckBasket} from '@features/basket/hooks/useCheckBasket';

const b = block('basket-header-controls');

export const BasketHeaderControls = () => {
    const {state} = useContext(AppContext);
    const {allChecked, selectedCount} = state.basket;

    const {checkBasket, isLoading, loadingText} = useCheckBasket();
    const [isModalOpenCheckLoading, setIsModalOpenCheckLoading] = useState(false);
    const {handleDeleteSelected, handleSelectAll} = useBasket();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleDeleteClick = () => {
        setIsModalOpen(true);
    };

    const handleCheckClick = async () => {
        setIsModalOpenCheckLoading(true);
        await checkBasket();
        setIsModalOpenCheckLoading(false);
    };

    const handleConfirmDelete = useCallback(() => {
        handleDeleteSelected();
        setIsModalOpen(false);
    }, [handleDeleteSelected]);

    return (
        <div className={b()}>
            <Checkbox
                size={'l'}
                className={b('select-all')}
                checked={allChecked}
                onChange={(event) => handleSelectAll(event.target.checked)}
            >
                <Text variant={'body-2'}>Выбрать всё</Text>
            </Checkbox>

            <Button
                view={'flat-danger'}
                disabled={selectedCount === 0}
                className={b('delete-selected')}
                onClick={handleDeleteClick}
            >
                <TrashBin />
                <Text variant={'body-2'}>Удалить выбранное</Text>
            </Button>

            <Button view={'flat-info'} className={b('check-items')} onClick={handleCheckClick}>
                <ClockArrowRotateLeft />
                <Text variant={'body-2'}>Проверить наличие и цены</Text>
            </Button>

            <BasketTrashModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onConfirm={handleConfirmDelete}
            />

            <CheckAvailableLoading
                open={isModalOpenCheckLoading && isLoading}
                text={loadingText}
                onClose={() => setIsModalOpenCheckLoading(false)}
            />
        </div>
    );
};
