import React from 'react';
import {useNavigate} from 'react-router-dom';
import block from 'bem-cn-lite';
import { UserAddress } from '@features/profile';

import './UserAddressPage.scss';

const b = block('person-info');

export const UserAddressPage: React.FC = () => {
    return (
        <div className={b()}>
            <UserAddress />
        </div>
    );
};
