import React, {useState, useContext} from 'react';
import block from 'bem-cn-lite';
import {Text, Tooltip} from '@gravity-ui/uikit';
import {Envelope, Copy} from '@gravity-ui/icons';
import ManagerPng from '@assets/avatar-manager.png';
import Whatsapp from '@assets/whatsapp-icon.svg';
import {ProfileContext} from 'app/Providers/ProfileProvider/ProfileProvider';

import './ContactCard.scss';

const b = block('contact-card');

interface ContactProps {
    children: React.ReactNode;
    copyText: string;
}

const Contact: React.FC<ContactProps> = ({children, copyText}) => {
    const [openTooltip, setOpenTooltip] = useState(false);
    return (
        <Tooltip
            content="Скопировано"
            placement="top-end"
            disabled={!openTooltip}
            openDelay={0}
            contentClassName={b('tooltip')}
            className={b('tooltip-container')}
        >
            <div
                className={b('copy')}
                onClick={() => {
                    setOpenTooltip(true);
                    navigator.clipboard.writeText(copyText);
                    setTimeout(() => setOpenTooltip(false), 1000);
                }}
            >
                {children}
            </div>
        </Tooltip>
    );
};

const ContactCard: React.FC = () => {
    const {stateProfile: {contacts: {staff: {manager, mop, supervisor}}}} = useContext(ProfileContext);

    return (
        <div className={b()}>
            <div className={b('contact-container')}>
                <img src={ManagerPng} alt="Менеджер" />

                <div className={b('contact-info')}>
                    <Text variant="subheader-2">Ваш менеджер:</Text>

                    <Text variant="body-2">{manager.name}</Text>

                    <Contact copyText={manager.email}>
                        <Envelope />
                        <Text variant="body-1">{manager.email}</Text>
                    </Contact>

                    <div className={b('contact-number')}>
                        <Contact copyText={manager.phone}>
                            <Copy />
                            <Text variant="body-1">{manager.phone}</Text>
                        </Contact>

                        <Tooltip
                            content={
                                <Text className={b('whatsapp-tooltip-content')}>
                                    Откроется Whatsapp в браузере
                                </Text>
                            }
                            placement="top-end"
                            contentClassName={b('whatsapp-tooltip')}
                            className={b('whatsapp-tooltip-container')}
                            openDelay={0}
                            closeDelay={0}
                            disabled={false}
                        >
                            <a
                                href={"https://web.whatsapp.com/send?phone=${manager.phone}"}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={b('whatsapp-link')}
                            >
                                <img src={Whatsapp} alt="Whatsapp" />
                            </a>
                        </Tooltip>
                    </div>
                </div>
            </div>

            <div className={b('separator')}></div>

            <div className={b('contact-container')}>
                <Text variant="subheader-2">
                    Специалист<br />клиентской<br />поддержки:
                </Text>

                <div className={b('contact-info')}>
                    <Text variant="body-2">{mop.name}</Text>

                    <Contact copyText={mop.email}>
                        <Envelope />
                        <Text variant="body-1">{mop.email}</Text>
                    </Contact>

                    <div className={b('contact-number')}>
                        <Contact copyText={mop.phone}>
                            <Copy />
                            <Text variant="body-1">{mop.phone}</Text>
                        </Contact>
                        <Tooltip
                            content={
                                <Text className={b('whatsapp-tooltip-content')}>
                                    Откроется Whatsapp в браузере
                                </Text>
                            }
                            placement="top-end"
                            contentClassName={b('whatsapp-tooltip')}
                            className={b('whatsapp-tooltip-container')}
                            openDelay={0}
                            closeDelay={0}
                            disabled={false}
                        >
                            <a
                                href={"https://web.whatsapp.com/send?phone=${mop.phone}"}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={b('whatsapp-link')}
                            >
                                <img src={Whatsapp} alt="Whatsapp" />
                            </a>
                        </Tooltip>
                    </div>
                </div>
            </div>

            <div className={b('contact-container')}>
                <Text variant="subheader-2">
                    Руководитель <br /> отдела продаж:
                </Text>

                <div className={b('contact-info')}>
                    <Text variant="body-2">{supervisor.name}</Text>

                    <Contact copyText={supervisor.email}>
                        <Envelope />
                        <Text variant="body-1">{supervisor.email}</Text>
                    </Contact>

                    <div className={b('contact-number')}>
                        <Contact copyText={supervisor.phone}>
                            <Copy />
                            <Text variant="body-1">{supervisor.phone}</Text>
                        </Contact>
                        <Tooltip
                            content={
                                <Text className={b('whatsapp-tooltip-content')}>
                                    Откроется Whatsapp в браузере
                                </Text>
                            }
                            placement="top-end"
                            contentClassName={b('whatsapp-tooltip')}
                            className={b('whatsapp-tooltip-container')}
                            openDelay={0}
                            closeDelay={0}
                            disabled={false}
                        >
                            <a
                                href={`https://web.whatsapp.com/send?phone=${supervisor.phone}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={b('whatsapp-link')}
                            >
                                <img src={Whatsapp} alt="Whatsapp" />
                            </a>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ContactCard;
