import {useContext, useMemo} from 'react';
import {defaultDirection, sortItems} from '../utils/sortUtils';
import {AppContext} from 'app/context';

export const useSortedItems = (
    sortOption: {property: string; direction: 'asc' | 'desc'} | null,
    sortPriority: string[]
) => {
    const {state} = useContext(AppContext);
    const {orders} = state;

    const currentSortPriority = useMemo(() => {
        if (sortOption) {
            return [
                sortOption.property,
                ...sortPriority.filter((item) => item !== sortOption.property)
            ];
        } else {
            return ['status-date', ...sortPriority];
        }
    }, [sortOption, sortPriority]);

    const sortedItems = useMemo(() => {
        return sortItems(orders.items, currentSortPriority, sortOption, defaultDirection);
    }, [orders.items, currentSortPriority, sortOption]);

    return sortedItems;
};
