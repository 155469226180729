import block from 'bem-cn-lite';
import {Header, Items} from './components';
import {OrderItemModel} from '@features/orders/interfaces';

import './OrdersItems.scss';

const b = block('orders-items');

interface OrdersItemsProps {
    sortOption: {property: string; direction: 'asc' | 'desc'} | null;
    items: OrderItemModel[];
    onSortChange: (field: string) => void;
}

export const OrdersItems: React.FC<OrdersItemsProps> = ({sortOption, items, onSortChange}) => {
    return (
        <div className={b()}>
            <Header sortOption={sortOption} onSortChange={onSortChange} />
            <Items items={items} />
        </div>
    );
};
