import {createContext} from 'react';

interface ToastVisibilityContextProps {
    isToasterVisible: boolean;
    setIsToasterVisible: (visible: boolean) => void;
    currentToastName: string | null;
    setCurrentToastName: (name: string | null) => void;
}

export const ToastVisibilityContext = createContext<ToastVisibilityContextProps>({
    isToasterVisible: false,
    setIsToasterVisible: () => {},
    currentToastName: null,
    setCurrentToastName: () => {}
});
