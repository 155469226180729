import {Trolley, Check, Box, Snail, Xmark, ShoppingCart, Wrench, Car} from '@gravity-ui/icons';
import {Text} from '@gravity-ui/uikit';

interface StatusIconMapperProps {
    status: string;
}

interface StatusTextColorMapperProps {
    status: string;
}

export const StatusIconMapper = ({status}: StatusIconMapperProps) => {
    switch (status) {
        case 'К отгрузке':
            return <Trolley style={{color: 'rgba(100, 122, 141, 1)'}} />;
        case 'Выдан':
            return <Check style={{color: 'rgba(48, 170, 110, 1)'}} />;
        case 'В обработке':
            return <Box style={{color: 'rgba(100, 122, 141, 1)'}} />;
        case 'В транзите':
            return <Snail style={{color: 'rgba(100, 122, 141, 1)'}} />;
        case 'Отказ':
            return <Xmark style={{color: 'rgba(233, 3, 58, 1)'}} />;
        case 'В заказе':
            return <ShoppingCart style={{color: 'rgba(100, 122, 141, 1)'}} />;
        case 'В работе':
            return <Wrench style={{color: 'rgba(100, 122, 141, 1)'}} />;
        case 'В пути':
            return <Car style={{color: 'rgba(100, 122, 141, 1)'}} />;
        default:
            return null;
    }
};

const statusColorMap: Record<string, 'positive-heavy' | 'danger-heavy' | 'misc-heavy'> = {
    Выдан: 'positive-heavy',
    Отказ: 'danger-heavy'
};

export const StatusTextColorMapper = ({status}: StatusTextColorMapperProps) => {
    const color = statusColorMap[status] || 'misc-heavy';

    return (
        <Text variant={'body-2'} color={color}>
            {status}
        </Text>
    );
};
