import block from 'bem-cn-lite';
import React from 'react';
import {Button as GButton, ButtonProps as GButtonProps} from '@gravity-ui/uikit';

import './Button.scss';

const b = block('at-button');

interface ButtonProps extends GButtonProps {
    design?: 'orange' | 'gray' | 'white';
}

export const Button: React.FC<ButtonProps> = (props) => {
    let className = b();

    switch (props.design) {
        case 'gray':
            className = b('gray');
            break;
        case 'white':
            className = b('white');
            break;
    }

    return (
        <GButton className={className} {...(props as GButtonProps)}>
            {props.children}
        </GButton>
    );
};
