import React from 'react';
import block from 'bem-cn-lite';
import {ToasterComponent, ToasterProvider} from '@gravity-ui/uikit';
import {Outlet} from 'react-router-dom';

import './Wrapper.scss';

const b = block('wrapper');

export const Wrapper: React.FC = () => {
    return (
        <ToasterProvider>
            <div className={b()}>
                <Outlet />
            </div>
            <ToasterComponent />
        </ToasterProvider>
    );
};
