import React, {useContext,useState} from 'react';
import { useNavigate  } from 'react-router-dom';
import block from 'bem-cn-lite';
import {Text, Avatar} from '@gravity-ui/uikit';
import {Xmark, Person, ArrowRightFromSquare} from '@gravity-ui/icons';
import {Button} from '@components/shared/button';
import {ModalQuit} from '@components/ModalQuit';

import './UserCard.scss';
import {AppContext} from '../../../../app/context';
import {ProfileContext} from 'app/Providers/ProfileProvider/ProfileProvider';
import './UserCard.scss';

const b = block('user-card');

interface UserCardProps {
    onClose: () => void;
}

const UserCard: React.FC<UserCardProps> = ({onClose}) => {
    const [showModalQuit, setShowModalQuit] = useState(false);
    const {emit} = useContext(AppContext);
    const navigate = useNavigate();
    const {stateProfile: {user, clients}} = useContext(ProfileContext);

    function handleLogout() {
        setShowModalQuit((preState) => !preState);
    }

    function handleClick () {
        navigate('/profile');
    }

    return (
        <div className={b()}>
            <div className={b('header')}>
                <Text variant="subheader-3">Профиль</Text>
                <Xmark className={b('close')} onClick={onClose} />
            </div>

            <div className={b('user')}>
                <Avatar className={b('avatar')} text={user.name} theme="brand" size="l" />
                <Text variant="subheader-1">{user.name}</Text>
                {/* В рамка MVP один клиент */}
                <Text className={b('title-company')} variant="body-1">{clients[0].name}</Text>
            </div>

            <Button size="l" className={b('button-profile')} onClick={handleClick}>
                <Person /> Перейти в профиль
            </Button>
            <Button size="l" view="flat" className={b('button-exit')} onClick={handleLogout}>
                <ArrowRightFromSquare /> Выйти из профиля
            </Button>
            <ModalQuit visibility={showModalQuit}
                stateModal={setShowModalQuit}
                positiveAction={() => emit('logout', {redirectTo: '/'})} />
        </div>
    );
};

export default UserCard;
