import React from 'react';
import block from 'bem-cn-lite';
import {CaretDown, CaretUp} from '@gravity-ui/icons';
import {SortOption} from '@features/search/interfaces';
import './SortSelector.scss';

const b = block('sort-selector');

export interface SortSelectorProps {
    className?: string;
    title: string;
    sortOption: SortOption;
    defaultDirection: 'asc' | 'desc';
    onSortChanged?: (sortOption: SortOption) => void;
}

export const SortSelector: React.FC<SortSelectorProps> = ({
    className,
    title,
    sortOption,
    defaultDirection,
    onSortChanged,
}) => {
    function handleOnClick() {
        if (onSortChanged) {
            const getSortDirection = (so: SortOption) => {
                return !so.isActive ? defaultDirection : so.direction === 'asc' ? 'desc' : 'asc';
            };

            const newSortOption = {
                ...sortOption,
                direction: getSortDirection(sortOption),
            } as SortOption;
            onSortChanged(newSortOption);
        }
    }

    return (
        <div className={className ? className + ' ' + b() : b()} onClick={handleOnClick}>
            <div className={b('title')}>{title}</div>
            {sortOption.direction === 'asc' ? (
                <CaretUp className={b('caret', sortOption.isActive ? 'active' : '')} />
            ) : (
                <CaretDown className={b('caret', sortOption.isActive ? 'active' : '')} />
            )}
        </div>
    );
};
