import React from 'react';
import block from 'bem-cn-lite';
import './DeliveryDays.scss';
import {Text} from '@gravity-ui/uikit';

const b = block('delivery-days');

export interface DeliveryDaysProps {
    value: number;
    className: string;
}

export const DeliveryDays: React.FC<DeliveryDaysProps> = (props) => {
    return <Text variant={'body-2'} className={b() + ' ' + props.className}>{props.value}</Text>;
};
