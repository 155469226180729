import React, {SyntheticEvent, useEffect, useState, useContext} from 'react';
import block from 'bem-cn-lite';
import {Text, Alert, Checkbox} from '@gravity-ui/uikit';
import {ProfileContext} from 'app/Providers/ProfileProvider/ProfileProvider';
import './UserNotification.scss';

const b = block('user-notification');

export const UserNotification: React.FC = () => {
    const {stateProfile: {notification}, setStateProfile} = useContext(ProfileContext);

    //Сказали что на MVP эту фичу реализовывать не буду.
    //поэтому дописать отправить апи либо еще что-то
    function handleClick(event: React.ChangeEvent<HTMLInputElement>) {
        const {id, checked} = event.target;
        setStateProfile({
            notification: {
                ...notification,
                [id]: checked
            }
        });
    }

    return (
        <div className={b()}>
            <Text variant='header-1'>Уведомления</Text>
            <Text variant='body-2' className={b('paragraph')}>Получать уведомления о статусе заказов:</Text>
            <Checkbox size='l' className={b('checkbox')} checked={notification.telegram} id='telegram' onChange={handleClick}><span>Telegram-bot <span className={b('name-bot')}>@AvtoTranzit_bot</span></span></Checkbox>
            <Checkbox size='l' className={b('checkbox')} checked={notification.email} id='email' onChange={handleClick}><span>email</span></Checkbox>
            <Checkbox size='l' className={b('checkbox')} checked={notification.sms} id='sms' onChange={handleClick}><span>sms*</span></Checkbox>
            <Text variant='caption-2' className={b('note')}>*кроме абонентов Tele2</Text>
            <Alert className={b('alert')} view='outlined' theme='info' title='Как подключить телеграм-бот' actions={
                <ol className={b('alert-list')}>
                    <li>Зарегистрируйтесь в чат-боте <span className={b('name-bot')}>@AvtoTranzit_bot</span></li>
                    <li>Сделайте то-то</li>
                    <li>Сделайте сё-то</li>
                    <li>Вы молодец!</li>
                </ol>
            } />
        </div>
    );
};
