import {ReactNode, useState, createContext, useContext} from 'react';
import {Profile, IProfileContext} from './type';
import {initialStateProfile} from '@data/TestDataProfile';
import {AppContext} from '../../context';

//TODO когда будет АПИ поставить пустышку initialStateProfile
const defaultState = {
    stateProfile: initialStateProfile,
    setStateProfile: ()=>{},
}

export const ProfileContext = createContext<IProfileContext>(defaultState);


export const ProfileProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const {state} = useContext(AppContext);
    const settings = state.priceLevelSettings;

    const [stateProfile, setStateProfile] = useState<Profile>({
        ...initialStateProfile,
        levelprice: {
            enabled: settings?.enabled,
            value: settings?.markupPercentage,
            visibility: {
                searchpage: settings?.displayInSearchResults,
                basketpage: settings?.displayInBasket
            }
        }
    });

    const updateProfileState = (newState: Partial<Profile>) => {
        setStateProfile(prevState => ({
            ...prevState,
            ...newState,
        }));
    };

    return (
        <ProfileContext.Provider value={{ stateProfile, setStateProfile: updateProfileState }}>
            {children}
        </ProfileContext.Provider>
    );
};