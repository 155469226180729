/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface UserClient {
    client: {
        /** @example "my_login" */
        login: string;
        /** @example "ООО Гарант плюс" */
        name: string;
    };
    /** @example "Бухгалтер" */
    role: string;
    permissions: UserClientPermission[];
}

export enum UserClientPermission {
    View = 'view',
    Suggest = 'suggest',
    Order = 'order'
}

export type RichClient = {
    login: string;
    /** @example "ООО Гарант плюс" */
    name: string;
    deliveryDayType: 'calendar' | 'work';
    status: 'enabled' | 'disabled';
    roles: ('suggest' | 'orders')[];
    /** @example "Бухгалтер" */
    role: string;
    permissions: UserClientPermission[];
    settings: UserClientSettings;
};

export interface UserClientSettingsPut {
    visibleExtraCharge: number;
    visibleExtraChargeView: ('suggest' | 'basket')[];
}

export interface UserClientSettings {
    visibleExtraCharge: number;
    visibleExtraChargeView: ('suggest' | 'basket')[];
}

export interface Proposal {
    id: string;
    basketItemId?: string;
    brand: string;
    brandName: string;
    article: string;
    articleName: string;
    description: string;
    quantity: number;
    price: number;
    isAnalog: boolean;
    multiplicity: number;
    delivery?: {
        expected: any;
        guaranteed: any;
    };
    /** @example {"official":true} */
    flags: Record<string, boolean>;
    rating: number;
    returnCondition: string;
    /** @format date-time */
    expiresAt: string;
}

export interface Basket {
    status: 'actual' | 'stale' | 'unavailable';
    total: number;
    items: (Proposal & {
        status: 'actual' | 'stale' | 'unavailable';
        itemsCnt: number;
        comment: string;
        /** @format date-time */
        createdAt: string;
    })[];
}

export interface RefreshedBasket {
    total: number;
    statusPrev: 'actual' | 'stale' | 'unavailable';
    statusNew: 'actual' | 'stale' | 'unavailable';
    items: Record<
        string,
        {
            prev: Proposal & {
                status: 'actual' | 'stale' | 'unavailable';
                itemsCnt: number;
                comment: string;
                /** @format date-time */
                createdAt: string;
            };
            new: Proposal & {
                status: 'actual' | 'stale' | 'unavailable';
                comment: string;
                /** @format date-time */
                createdAt: string;
            };
            changedFields: string[];
        }
    >;
}

/**
 * Словарь в виде идентификатор - объект с количеством позиций и комментарием.  Если количество позиций 0, то позиция удаляется. Обновляются только позиции, которые переданы
 * @example {"proposal-identifier":{"itemsCnt":600,"comment":"метка"}}
 */
export type BasketPatch = Record<
    string,
    {
        itemsCnt: number;
        comment?: string;
    }
>;

export interface OrderPost {
    basketItems: string[];
}

export interface OrderPostResult {
    success: boolean;
    errors: (
        | 'finance.dept.exists'
        | 'finance.available.not_enough'
        | 'basket.stale'
        | 'basket.unavailable'
    )[];
    orderId?: string;
    finance?: {
        paymentType?: 'credit' | 'unlimited';
        orderTotal?: number;
        available?: number;
        dept?: any;
    };
    basket?: {
        stale?: string[];
        unavailable?: string[];
    };
}

export interface OrderSearch {
    limit: number;
    offset: number;
    filter?: {
        /** @format date-time */
        dateFrom?: string;
        /** @format date-time */
        dateTo?: string;
        /** @format date-time */
        deliveryDateFrom?: string;
        /** @format date-time */
        deliveryDateTo?: string;
        statusIn?: string[];
        refIdIn?: string[];
        brandNameIn?: string[];
        articleIn?: string[];
        priceFrom?: number;
        priceTo?: number;
        sumFrom?: number;
        sumTo?: number;
        hasComment?: boolean;
    };
    sort?: {
        direction?: 'asc' | 'desc';
        field?: 'date' | 'delivery' | 'status' | 'ref_id' | 'price' | 'sum';
    }[];
}

export interface Order {
    /** @format date-time */
    createdAt: string;
    deliveryInfo: {
        expected: any;
        guaranteed: any;
    };
    /** @example "00001" */
    refId: string;
    /** @example {"created":{"id":"created","name":"Новый","qty":4,"createdAt":"2019-10-12T07:20:50.52Z"}} */
    status: {
        id: string;
        name: string;
        qty: number;
        /** @format date-time */
        createdAt: string;
    };
    /** @example {"official":true} */
    flags: Record<string, boolean>;
    return: 'limited' | 'impossible' | 'possible' | 'unknown';
    /** @example "10-25142-SX_радиатор системы охлаждения!\\ Volvo S40/V40 1.6-2.0 16V 95>" */
    articleName: string;
    /** @example "10-25142-SX" */
    article: string;
    /** @example "Stellox" */
    brandName: string;
    quantity: number;
    /** @example 7854.23 */
    price: number;
    /** @example 7854.23 */
    sum: number;
    comment?: string;
}

import type {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    HeadersDefaults,
    ResponseType
} from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
    extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseType;
    /** request body */
    body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
    extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
    securityWorker?: (
        securityData: SecurityDataType | null
    ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
    secure?: boolean;
    format?: ResponseType;
}

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
    public instance: AxiosInstance;
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private secure?: boolean;
    private format?: ResponseType;

    constructor({
        securityWorker,
        secure,
        format,
        ...axiosConfig
    }: ApiConfig<SecurityDataType> = {}) {
        this.instance = axios.create({
            ...axiosConfig,
            baseURL: axiosConfig.baseURL || 'https://api.zapnsk.ru/api/usr'
        });
        this.secure = secure;
        this.format = format;
        this.securityWorker = securityWorker;
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected mergeRequestParams(
        params1: AxiosRequestConfig,
        params2?: AxiosRequestConfig
    ): AxiosRequestConfig {
        const method = params1.method || (params2 && params2.method);

        return {
            ...this.instance.defaults,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...((method &&
                    this.instance.defaults.headers[
                        method.toLowerCase() as keyof HeadersDefaults
                    ]) ||
                    {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {})
            }
        };
    }

    protected stringifyFormItem(formItem: unknown) {
        if (typeof formItem === 'object' && formItem !== null) {
            return JSON.stringify(formItem);
        } else {
            return `${formItem}`;
        }
    }

    protected createFormData(input: Record<string, unknown>): FormData {
        if (input instanceof FormData) {
            return input;
        }
        return Object.keys(input || {}).reduce((formData, key) => {
            const property = input[key];
            const propertyContent: any[] = property instanceof Array ? property : [property];

            for (const formItem of propertyContent) {
                const isFileType = formItem instanceof Blob || formItem instanceof File;
                formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
            }

            return formData;
        }, new FormData());
    }

    public request = async <T = any, _E = any>({
        secure,
        path,
        type,
        query,
        format,
        body,
        ...params
    }: FullRequestParams): Promise<AxiosResponse<T>> => {
        const secureParams =
            ((typeof secure === 'boolean' ? secure : this.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const responseFormat = format || this.format || undefined;

        if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
            body = this.createFormData(body as Record<string, unknown>);
        }

        if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
            body = JSON.stringify(body);
        }

        return this.instance.request({
            ...requestParams,
            headers: {
                ...(requestParams.headers || {}),
                ...(type ? {'Content-Type': type} : {})
            },
            params: query,
            responseType: responseFormat,
            data: body,
            url: path
        });
    };
}

/**
 * @title User Gateway API
 * @version 0.0.1
 * @baseUrl https://api.zapnsk.ru/api/usr
 *
 * API для фронтенда
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
    contactUs = {
        /**
         * @description Send contact us info
         *
         * @tags Utils
         * @name ContactUsCreate
         * @summary Send contact us info
         * @request POST:/contact-us
         * @secure
         */
        contactUsCreate: (
            data: {
                name: string;
                phone: string;
                email: string;
                message?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/contact-us`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            })
    };
    login = {
        /**
         * @description Login
         *
         * @tags Login
         * @name LoginCreate
         * @summary Login
         * @request POST:/login
         * @secure
         */
        loginCreate: (
            data: {
                type?: 'password' | 'token';
                login?: string;
                password?: string;
                token?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    /**
                     * @format jwt
                     * @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c"
                     */
                    access_token: string;
                    /**
                     * In seconds
                     * @example 600
                     */
                    expires_in: string;
                },
                any
            >({
                path: `/login`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.UrlEncoded,
                format: 'json',
                ...params
            })
    };
    resendPassword = {
        /**
         * @description Resend user password
         *
         * @tags Login
         * @name ResendPasswordCreate
         * @summary Resend user password
         * @request POST:/resend-password
         * @secure
         */
        resendPasswordCreate: (
            data: {
                login: string;
                email: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/resend-password`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            })
    };
    token = {
        /**
         * @description Get client specific token
         *
         * @tags Login
         * @name TokenCreate
         * @summary Get client specific token
         * @request POST:/token
         * @secure
         */
        tokenCreate: (
            data: {
                client: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    /**
                     * Contains extra fields: aud = client.login, permissions = user_client.permissions
                     * @format jwt
                     * @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c"
                     */
                    access_token: string;
                    /**
                     * In seconds
                     * @example 600
                     */
                    expires_in: string;
                },
                any
            >({
                path: `/token`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.UrlEncoded,
                format: 'json',
                ...params
            })
    };
    client = {
        /**
         * @description Get user clients
         *
         * @tags User Client
         * @name ClientList
         * @summary Get user clients
         * @request GET:/client
         * @secure
         */
        clientList: (
            query?: {
                /** @example "my_lo" */
                q?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    total: number;
                    items: UserClient;
                },
                any
            >({
                path: `/client`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * @description Get rich client
         *
         * @tags Client
         * @name ClientDetail
         * @summary Get rich client
         * @request GET:/client/{login}
         * @secure
         */
        clientDetail: (login?: string, params: RequestParams = {}) =>
            this.request<RichClient, any>({
                path: `/client/${login}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * @description Set up client settings
         *
         * @tags Client
         * @name SettingsUpdate
         * @summary Set up client settings
         * @request PUT:/client/{login}/settings
         * @secure
         */
        settingsUpdate: (data: UserClientSettingsPut, login?: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/client/${login}/settings`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            })
    };
    suggest = {
        /**
         * @description Suggest
         *
         * @tags Suggest
         * @name SuggestCreate
         * @summary Suggest
         * @request POST:/suggest
         * @secure
         */
        suggestCreate: (
            data: {
                brand: string;
                article: string;
                useAnalogs: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    total: number;
                    asyncKey?: string;
                    items: Proposal[];
                },
                any
            >({
                path: `/suggest`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            }),

        /**
         * @description Get async suggestion proposals
         *
         * @tags Suggest
         * @name AsyncProposalsDetail
         * @summary Get async suggestion proposals
         * @request GET:/suggest/async-proposals/{key}
         * @secure
         */
        asyncProposalsDetail: (key: string, params: RequestParams = {}) =>
            this.request<
                {
                    total: number;
                    state: 'in_progress' | 'done' | 'clear';
                    items: Proposal[];
                },
                any
            >({
                path: `/suggest/async-proposals/${key}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            })
    };
    suggestBrand = {
        /**
         * @description Suggest brand by article
         *
         * @tags Suggest
         * @name SuggestBrandList
         * @summary Suggest brand by article
         * @request GET:/suggest-brand
         * @secure
         */
        suggestBrandList: (
            query: {
                article: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    total: number;
                    items: {
                        /** @example "Stellox" */
                        name: string;
                        /** @example 4095 */
                        code: string;
                    }[];
                },
                any
            >({
                path: `/suggest-brand`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            })
    };
    basket = {
        /**
         * @description Get current basket
         *
         * @tags Basket
         * @name BasketList
         * @summary Get current basket
         * @request GET:/basket
         * @secure
         */
        basketList: (params: RequestParams = {}) =>
            this.request<Basket, any>({
                path: `/basket`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * @description Clear basket
         *
         * @tags Basket
         * @name BasketDelete
         * @summary Clear basket
         * @request DELETE:/basket
         * @secure
         */
        basketDelete: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/basket`,
                method: 'DELETE',
                secure: true,
                ...params
            }),

        /**
         * @description Update current basket
         *
         * @tags Basket
         * @name BasketPartialUpdate
         * @summary Update current basket
         * @request PATCH:/basket
         * @secure
         */
        basketPartialUpdate: (data: BasketPatch, params: RequestParams = {}) =>
            this.request<Basket, any>({
                path: `/basket`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            }),

        /**
         * @description Clear basket
         *
         * @tags Basket
         * @name RefreshCreate
         * @summary Clear basket
         * @request POST:/basket/refresh
         * @secure
         */
        refreshCreate: (
            data: {
                /** Массив идентификаторов предложение которые нужно актуализировать */
                items: string[];
            },
            params: RequestParams = {}
        ) =>
            this.request<RefreshedBasket, any>({
                path: `/basket/refresh`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            })
    };
    order = {
        /**
         * @description Create order
         *
         * @tags Order
         * @name OrderCreate
         * @summary Create order
         * @request POST:/order
         * @secure
         */
        orderCreate: (data: OrderPost, params: RequestParams = {}) =>
            this.request<OrderPostResult, any>({
                path: `/order`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            })
    };
    orderSearch = {
        /**
         * @description Search orders
         *
         * @tags Order
         * @name OrderSearchCreate
         * @summary Search orders
         * @request POST:/order-search
         * @secure
         */
        orderSearchCreate: (data: OrderSearch, params: RequestParams = {}) =>
            this.request<
                {
                    total: number;
                    items: Order[];
                },
                any
            >({
                path: `/order-search`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            })
    };
    orderSearchFilterValue = {
        /**
         * @description Get value list (50 max) for orders filter
         *
         * @tags Order
         * @name OrderSearchFilterValueDetail
         * @summary Get value list (50 max) for orders filter
         * @request GET:/order-search-filter-value/{key}
         * @secure
         */
        orderSearchFilterValueDetail: (
            key: 'status' | 'ref_id' | 'brand_name',
            query?: {
                q?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    values: {
                        id: string;
                        name: string;
                    }[];
                },
                any
            >({
                path: `/order-search-filter-value/${key}`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            })
    };
}
