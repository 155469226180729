import React from 'react';
import {useMediaQuery} from 'react-responsive';
import block from 'bem-cn-lite';
import './Header.scss';
import {Icon, Text} from '@gravity-ui/uikit';
import {CaretDown, CaretUp} from '@gravity-ui/icons';

const b = block('header-basket-items');

interface HeaderProps {
    sortOption: {property: string; direction: 'asc' | 'desc'} | null;
    onSortChange: (field: string) => void;
}

export const Header: React.FC<HeaderProps> = ({sortOption, onSortChange}) => {
    const isSmallScreen = useMediaQuery({query: '(max-width: 1280px)'});

    const getIconColor = (field: string) => {
        return sortOption?.property === field ? '#BD8E4B' : 'inherit';
    };

    const renderCaretIcon = (field: string) => {
        if (sortOption?.property === field) {
            return sortOption.direction === 'asc' ? (
                <div>
                    <CaretUp style={{ color: getIconColor(field) }} />
                </div>
            ) : (
                <div><CaretDown style={{ color: getIconColor(field) }} /></div>
            );
        } else {
            return <div><CaretUp style={{ color: getIconColor(field) }} /></div>;
        }
    };
    

    return (
        <div className={b()}>
            <div className={b('block', b('is-selected'))} />
            {!isSmallScreen && (
                <div className={b('block', b('date'))} onClick={() => onSortChange('date')}>
                    <Text variant={'subheader-2'}>Дата</Text>
                    {renderCaretIcon('date')}
                </div>
            )}
            <div className={b('block', b('article'))}>
                <Text variant={'subheader-2'}>Бренд/Артикул</Text>
            </div>
            <div className={b('block', b('icons'))}></div>
            <div className={b('block', b('rating'))} onClick={() => onSortChange('rating')}>
                <Text variant={'subheader-2'}>Рейтинг</Text>
                {renderCaretIcon('rating')}
            </div>
            {!isSmallScreen && (
                <div className={b('block', b('quantity'))} onClick={() => onSortChange('quantity')}>
                    <Text variant={'subheader-2'}>Наличие</Text>
                    {renderCaretIcon('quantity')}
                </div>
            )}
            <div className={b('block', b('delivery-days'))} onClick={() => onSortChange('deliveryDays')}>
                <Text variant={'subheader-2'}>Срок, дн</Text>
                {renderCaretIcon('deliveryDays')}
            </div>
            <div className={b('block', b('delivery-date'))} onClick={() => onSortChange('delivery-date')}>
                <Text variant={'subheader-2'}>К дате</Text>
                {renderCaretIcon('delivery-date')}
            </div>
            <div className={b('block', b('price'))} onClick={() => onSortChange('price')}>
                <Text variant={'subheader-2'}>Цена</Text>
                {renderCaretIcon('price')}
            </div>
            <div className={b('block', b('amount'))}>
                <Text variant={'subheader-2'}>Сумма</Text>
            </div>
            <div className={b('block', b('quantity-counter'))} />
            <div className={b('block', b('aux-controls'))} />
        </div>
    );
};
