import {PriceLevelSettings} from 'app/priceLevelSettings';

export function getAdjustedPrice(
    price: number,
    priceLevelSettings: PriceLevelSettings,
    page: 'search' | 'basket' | 'orders'
): number {
    if (!priceLevelSettings.enabled) {
        return price;
    }

    const shouldApplyMarkup =
        (page === 'search' && priceLevelSettings.displayInSearchResults) ||
        (page === 'basket' && priceLevelSettings.displayInBasket);

    if (shouldApplyMarkup) {
        const adjustedPrice = price * (1 + priceLevelSettings.markupPercentage / 100);
        return Math.round(adjustedPrice * 100) / 100;
    }

    return price;
}
