import React, {Dispatch, SetStateAction, useContext, useState} from 'react';
import block from 'bem-cn-lite';
import {Button, Icon, Popup, Text} from '@gravity-ui/uikit';
import {Rectangles4, Sliders} from '@gravity-ui/icons';
import {OrdersFilters} from '../OrdersFilters';
import {FiltersModel} from '@features/orders/interfaces';
import {usePopoverAuxControls} from '@hooks';
import {PopupSettingsContent} from './components/PopupSettingsContent';
import {AppContext} from 'app/context';

import './OrdersHeaderControls.scss';

const b = block('orders-header-controls');

interface OrdersHeaderControlsProps {
    filters: FiltersModel;
    setFilters: Dispatch<SetStateAction<FiltersModel>>;
}

export const OrdersHeaderControls: React.FC<OrdersHeaderControlsProps> = ({
    filters,
    setFilters
}) => {
    const {open, buttonRef, toggleOpen, closePopover} = usePopoverAuxControls();
    const {state} = useContext(AppContext);

    const [filtersVisible, setFiltersVisible] = useState(true);

    const toggleFilters = () => {
        setFiltersVisible(!filtersVisible);
    };

    const hasActiveFilters = () => {
        return (
            filters.createdAtRange ||
            filters.deliveryDateRange ||
            filters.brands.some((b) => b.isSelected) ||
            filters.statuses.some((s) => s.isSelected) ||
            filters.refs.some((r) => r.isSelected) ||
            filters.articles.some((a) => a.isSelected) ||
            filters.price.from !== undefined ||
            filters.price.to !== undefined ||
            filters.sum.from !== undefined ||
            filters.sum.to !== undefined ||
            filters.onlyWithComments
        );
    };

    const hasActiveSettings = () => {
        const defaultOrder = Array.from({length: 10}, (_, i) => i + 1);

        const isOrderChanged = !(
            state.orders.config.itemsOrder.length === defaultOrder.length &&
            state.orders.config.itemsOrder.every((value, index) => value === defaultOrder[index])
        );

        const hasUncheckedItems = Object.values(state.orders.config.itemsChecked).some(
            (isChecked) => !isChecked
        );

        return isOrderChanged || hasUncheckedItems;
    };

    return (
        <div className={b()}>
            <div className={b('controls-container')}>
                <Text variant={'header-2'}>Заказы</Text>

                <div className={b('header-buttons')}>
                    <Button
                        size="l"
                        onClick={toggleFilters}
                        style={{
                            backgroundColor: hasActiveFilters()
                                ? 'rgba(255, 190, 92, 0.3)'
                                : 'transparent'
                        }}
                    >
                        <div className={b('button-content')}>
                            <Icon data={Sliders} />
                            {hasActiveFilters() && <span className={b('check-icon')} />}
                        </div>

                        <Text>Фильтры</Text>
                    </Button>

                    <Button
                        size="l"
                        ref={buttonRef}
                        onClick={toggleOpen}
                        style={{
                            backgroundColor: hasActiveSettings()
                                ? 'rgba(255, 190, 92, 0.3)'
                                : 'transparent'
                        }}
                    >
                        <div className={b('button-content')}>
                            <Icon data={Rectangles4} />
                            {hasActiveSettings() && <span className={b('check-icon')} />}
                        </div>

                        <Text>Настройки</Text>
                    </Button>

                    <Popup
                        anchorRef={buttonRef}
                        onClose={closePopover}
                        open={open}
                        placement="bottom-end"
                    >
                        <PopupSettingsContent onClose={closePopover} />
                    </Popup>
                </div>
            </div>

            {filtersVisible && <OrdersFilters filters={filters} setFilters={setFilters} />}
        </div>
    );
};
