import React from 'react';
import block from 'bem-cn-lite';
import {Outlet} from 'react-router-dom';
import {FooterBlock, Header} from '@components';

import './PersonalAccountLayout.scss';

const b = block('personal-account-layout');

export const PersonalAccountLayout = () => {
    return (
        <div className={b()}>
            <Header />

            <div className={b('content')}>
                <Outlet />
            </div>

            <FooterBlock isMinimal />
        </div>
    );
};
