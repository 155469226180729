import React from 'react';
import block from 'bem-cn-lite';
import {Text as GText} from '@gravity-ui/uikit';

import NotFoundSvg from '@assets/search-not-found.svg';

import './NotFound.scss';

const b = block('not-found');

export interface NotFoundProps {
    showClearFilters?: boolean;
}

export const NotFound: React.FC<NotFoundProps> = (props) => {
    return (
        <div className={b()}>
            <img className={b('image')} src={NotFoundSvg}></img>
            <GText className={b('text1', props.showClearFilters ? 'bold' : '')}>
                По вашему запросу ничего не нашлось.
            </GText>
            {props.showClearFilters ? <GText>Попробуйте изменить настройки фильтров</GText> : <></>}
        </div>
    );
};
