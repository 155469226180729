import React from 'react';
import block from 'bem-cn-lite';
import {Text as GText, Switch} from '@gravity-ui/uikit';
import './Option.scss';

const b = block('option');

export interface OptionProps {
    title: string;
    value?: boolean;
    onValueChange?: (value: boolean) => void;
    disabled?: boolean;
}

export const Option: React.FC<OptionProps> = (props) => {
    return (
        <div className={b()}>
            <GText className={b('title')}>{props.title}</GText>
            <Switch
                className={b('switch')}
                size="m"
                checked={props.value}
                onChange={(e) => props.onValueChange && props.onValueChange(e.target.checked)}
                disabled={props.disabled}
            ></Switch>
        </div>
    );
};
