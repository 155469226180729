import React, {useContext, useEffect, useState} from 'react';
import block from 'bem-cn-lite';
import {Loader, Text} from '@gravity-ui/uikit';
import {BasketHeaderControls, BasketItems, BasketSummary} from '@features/basket';
import {BasketEmpty} from '@features/basket/components/BasketEmpty/BasketEmpty';
import {BasketItemsOut} from '@features/basket/components/BasketItemsOut';
import {CApi as userGwApi} from '@services/api';
import {AppContext} from 'app/context';
import {basketStorage} from 'app/basketStorage';
import {defaultDirection, defaultSortPriority} from '@features/basket/utils/sortUtils';
import {ProposalModel} from '@features/basket/interfaces/ProposalModel';
import {useSortedItems} from '@features/basket/hooks/useSortedItems';

import './BasketPage.scss';

const b = block('basket-page');

export const BasketPage: React.FC = () => {
    return <BasketContent />;
};

const BasketContent: React.FC = () => {
    const {state, emit} = useContext(AppContext);
    const {items, itemsOut} = state.basket;

    const [isLoading, setIsLoading] = useState(true);

    const [sortPriority, setSortPriority] = useState<string[]>(defaultSortPriority);
    // Состояние для выбранного поля сортировки и направления
    const [sortOption, setSortOption] = useState<{
        property: string;
        direction: 'asc' | 'desc';
    } | null>(null);

    useEffect(() => {
        userGwApi()
            .basket.basketList()
            .then((r) => {
                const basketData = r.data.items.map((x) => {
                    return {
                        article: x.article,
                        articleName: x.articleName,
                        basketItemId: x.basketItemId,
                        brand: x.brand,
                        brandName: x.brandName,
                        comment: x.comment,
                        createdAt: (() => {
                            const date = new Date(Date.parse(x.createdAt));
                            date.setSeconds(0, 0);
                            return date.getTime();
                        })(),
                        deliveryDate: new Date(Date.parse(x.delivery?.expected.client.date)),
                        deliveryDays: x.delivery?.expected.client.days,
                        description: x.description,
                        expiresAt: new Date(Date.parse(x.expiresAt)),
                        flags: x.flags,
                        id: x.id,
                        isAnalog: x.isAnalog,
                        itemsCnt: x.itemsCnt,
                        multiplicity: x.multiplicity,
                        price: x.price,
                        quantity: x.quantity,
                        rating: x.rating,
                        returnCondition: x.returnCondition,
                        status: x.status
                    } as ProposalModel;
                });
                console.log('Данные получены:', basketData);

                const savedBasket = basketStorage.get() || {items: [], itemsOut: []};

                const updatedItems: Array<(typeof basketData)[0]> = [];
                const updatedItemsOut: Array<(typeof basketData)[0]> = [];

                basketData.forEach((item) => {
                    const savedItem =
                        savedBasket.items.find((saved) => saved.id === item.id) ||
                        savedBasket.itemsOut.find((savedOut) => savedOut.id === item.id);

                    // Если элемент сохранён, используем его состояние checked, иначе устанавливаем true по умолчанию
                    const isChecked = savedItem?.checked ?? true;
                    const amount = item.price * item.itemsCnt;

                    const newItem = {
                        ...item,
                        checked: isChecked,
                        amount: amount,
                        newPrice: undefined // Изначально нет новой цены
                    };

                    // Распределяем товары по доступности
                    if (item.status === 'unavailable') {
                        updatedItemsOut.push(newItem);
                    } else {
                        updatedItems.push(newItem);
                    }
                });

                emit('updateBasket', {
                    basket: {
                        items: updatedItems,
                        itemsOut: updatedItemsOut
                    }
                });

                emit('updateCounter', {
                    counterName: 'basketItems',
                    value: updatedItems.length
                });
            })
            .catch((err) => {
                console.error('Ошибка:', err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        // Рассчитываем общее количество и сумму для отмеченных товаров
        const totalQuantity = items.reduce(
            (prev, curr) => (curr.checked ? prev + curr.itemsCnt : prev),
            0
        );
        const totalAmount = items.reduce(
            (prev, curr) => (curr.checked ? prev + curr.amount : prev),
            0
        );

        // Проверяем, все ли товары отмечены
        const allChecked = items.every((item) => item.checked);
        const allCheckedItemsOut = itemsOut.every((item) => item.checked);

        // Количество выбранных элементов
        const selectedCount = items.filter((item) => item.checked).length;

        emit('updateBasket', {
            basket: {
                summary: {totalAmount, totalQuantity},
                allChecked,
                allCheckedItemsOut,
                selectedCount
            }
        });
    }, [items, itemsOut]);

    /*Разделение на доступные и недоступные после обновление, для отображения разных текстов ошибки*/
    const allItems = [...items, ...itemsOut];

    const itemsUnavailableOccurred = allItems.some((item) => item.quantityChangedToZero);
    const itemsCntExceedsQuantityOccurred = allItems.some((item) => item.quantityDecreased);
    /*Разделение на доступные и недоступные после обновление, для отображения разных текстов ошибки*/

    const handleSortChange = (field: string) => {
        setSortOption((prevSortOption) => {
            if (prevSortOption && prevSortOption.property === field) {
                return {
                    property: field,
                    direction: prevSortOption.direction === 'asc' ? 'desc' : 'asc'
                };
            } else {
                const direction = defaultDirection.get(field) === 1 ? 'asc' : 'desc';
                return {
                    property: field,
                    direction: direction as 'asc' | 'desc'
                };
            }
        });

        setSortPriority((prevSortPriority) => {
            const newSortPriority = prevSortPriority.filter((item) => item !== field);
            newSortPriority.unshift(field);
            return newSortPriority;
        });
    };

    const sortedItems = useSortedItems(sortOption, sortPriority);

    if (isLoading) {
        return (
            <div className={b('loader')}>
                <Loader size="l" />
            </div>
        );
    }

    if (items.length === 0 && itemsOut.length === 0) {
        return (
            <div className={b()}>
                <Text variant={'header-2'}>Корзина</Text>
                <BasketEmpty />
            </div>
        );
    }

    return (
        <div className={b()}>
            <Text variant={'header-2'}>Корзина</Text>
            <BasketHeaderControls />
            <BasketItems
                items={sortedItems.filter((item) => item.source === 'items')}
                onSortChange={handleSortChange}
                sortOption={sortOption}
            />
            <BasketSummary />

            {itemsOut.length > 0 && (
                <BasketItemsOut
                    itemsUnavailableOccurred={itemsUnavailableOccurred}
                    itemsCntExceedsQuantityOccurred={itemsCntExceedsQuantityOccurred}
                    items={sortedItems.filter((item) => item.source === 'itemsOut')}
                />
            )}
        </div>
    );
};
