import React, {forwardRef} from 'react';
import block from 'bem-cn-lite';
import {Button, Text, Tooltip} from '@gravity-ui/uikit';

import './TooltipButton.scss';

const b = block('aux-controls-tooltip');

interface TooltipButtonProps {
    tooltipContent: React.ReactNode;
    onClick?: () => void;
    icon: React.ReactNode;
    className?: string;
    disabled?: boolean;
    view?: 'outlined' | 'outlined-warning';
    style?: React.CSSProperties;
}

export const TooltipButton = forwardRef<HTMLButtonElement, TooltipButtonProps>(
    ({tooltipContent, onClick, icon, className, disabled = false, view = 'outlined', style}, ref) => (
        <Tooltip
            content={<Text className={b('aux-controls-tooltip-content')}>{tooltipContent}</Text>}
            contentClassName={b('aux-controls-tooltip')}
            className={b('aux-controls-tooltip-container')}
            placement="top-end"
            openDelay={0}
            closeDelay={0}
            disabled={disabled}
        >
            <Button view={view} ref={ref} onClick={onClick} className={className} style={style}>
                {icon}
            </Button>
        </Tooltip>
    ),
);
