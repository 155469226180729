import React from 'react';
import block from 'bem-cn-lite';
import {Modal, Button, Text} from '@gravity-ui/uikit';
import {Xmark} from '@gravity-ui/icons';

import './BasketTrashModal.scss';

const b = block('basket-trash-modal');

interface BasketTrashModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

export const BasketTrashModal: React.FC<BasketTrashModalProps> = ({open, onClose, onConfirm}) => {
    return (
        <Modal open={open} onClose={onClose} className={b()}>
            <div className={b('content-container')}>
                <div className={b('content-text')}>
                    <Text variant="subheader-3">Вы уверены, что хотите <br/>удалить товары?</Text>
                    <Text variant="body-1">Это действие нельзя будет отменить.</Text>
                </div>

                <Xmark onClick={onClose} className={b('close-icon')} />
            </div>

            <div className={b('buttons-container')}>
                <Button view="normal" size="m" onClick={onClose} className={b('cancel-button')}>
                    Отмена
                </Button>
                <Button view="flat" size="m" onClick={onConfirm} className={b('confirm-button')}>
                    Удалить
                </Button>
            </div>
        </Modal>
    );
};
