export interface PriceLevelSettings {
    enabled: boolean; // Включен ли уровень цен
    markupPercentage: number; // Процент наценки
    displayInSearchResults: boolean; // Отображать в результатах поиска
    displayInBasket: boolean; // Отображать в корзине
}

const PRICE_LEVEL_SETTINGS_KEY = 'priceLevelSettings';

class PriceLevelSettingsStorage {
    set = (settings: PriceLevelSettings) =>
        localStorage.setItem(PRICE_LEVEL_SETTINGS_KEY, JSON.stringify(settings));
    get = (): PriceLevelSettings | null => {
        const settings = localStorage.getItem(PRICE_LEVEL_SETTINGS_KEY);
        return settings ? JSON.parse(settings) : null;
    };
    clear = () => localStorage.removeItem(PRICE_LEVEL_SETTINGS_KEY);
}

export const priceLevelSettingsStorage = new PriceLevelSettingsStorage();
