import {Button, Icon, Switch, Text, TextInput} from '@gravity-ui/uikit';
import block from 'bem-cn-lite';
import {TrashBin} from '@gravity-ui/icons';
import {CustomSelect} from './components/CustomSelect';

import './OrdersFilters.scss';
import {FiltersModel} from '@features/orders/interfaces';
import {Dispatch, SetStateAction, useContext} from 'react';
import {formatCurrencyRub} from '@utils';
import {CustomRangeDatePicker} from './components/CustomRangeDatePicker/CustomRangeDatePicker';
import {AppContext} from 'app/context';

const b = block('orders-filters');

interface OrdersFiltersProps {
    filters: FiltersModel;
    setFilters: Dispatch<SetStateAction<FiltersModel>>;
}

export const OrdersFilters: React.FC<OrdersFiltersProps> = ({filters, setFilters}) => {
    const {emit} = useContext(AppContext);

    function handleApplyStatusFilter(values: string[]) {
        setFilters((prev) => {
            return {
                ...prev,
                statuses: prev.statuses.map((x) => {
                    return {
                        ...x,
                        isSelected: values.includes(x.value)
                    };
                })
            };
        });
    }

    function handleApplyBrandsFilter(values: string[]) {
        setFilters((prev) => {
            return {
                ...prev,
                brands: prev.brands.map((x) => {
                    return {
                        ...x,
                        isSelected: values.includes(x.value)
                    };
                })
            };
        });
    }

    function handleRefsChange(values: string[]) {
        setFilters((prev) => {
            return {
                ...prev,
                refs: prev.refs.map((x) => {
                    return {
                        ...x,
                        isSelected: values.includes(x.value)
                    };
                })
            };
        });
    }

    function handleArticlesChange(values: string[]) {
        setFilters((prev) => {
            return {
                ...prev,
                articles: prev.articles.map((x) => {
                    return {
                        ...x,
                        isSelected: values.includes(x.value)
                    };
                })
            };
        });
    }

    function handlePriceFromChange(value: string) {
        setFilters((prev) => {
            return {
                ...prev,
                price: {
                    ...prev.price,
                    from: getNumberFromStringValue(value)
                }
            };
        });
    }

    function handlePriceToChange(value: string) {
        setFilters((prev) => {
            return {
                ...prev,
                price: {
                    ...prev.price,
                    to: getNumberFromStringValue(value)
                }
            };
        });
    }

    function handleSumFromChange(value: string) {
        setFilters((prev) => {
            return {
                ...prev,
                sum: {
                    ...prev.sum,
                    from: getNumberFromStringValue(value)
                }
            };
        });
    }

    function handleSumToChange(value: string) {
        setFilters((prev) => {
            return {
                ...prev,
                sum: {
                    ...prev.sum,
                    to: getNumberFromStringValue(value)
                }
            };
        });
    }
    function handleOnlyWithCommentsChange(checked: boolean) {
        setFilters((prev) => {
            return {
                ...prev,
                onlyWithComments: checked
            };
        });
    }

    function resetFiltersState(filters: FiltersModel): FiltersModel {
        const filterKeys = ['statuses', 'brands', 'refs', 'articles'] as const;

        const resetSelection = <T extends {isSelected: boolean}>(items: T[]): T[] => {
            return items.map((item) => ({
                ...item,
                isSelected: false
            }));
        };

        const resetFilters: FiltersModel = {
            ...filters,
            createdAtRange: null,
            deliveryDateRange: null,
            price: {},
            sum: {},
            onlyWithComments: false
        };

        filterKeys.forEach((key) => {
            if (filters[key]) {
                resetFilters[key] = resetSelection(filters[key]);
            }
        });

        return resetFilters;
    }

    function handleResetAllClick() {
        const resetFilters = resetFiltersState(filters);

        setFilters(resetFilters);

        emit('updateOrders', {
            orders: {
                filters: resetFilters
            }
        });
    }

    const saveFilters = () => {
        emit('updateOrders', {
            orders: {
                filters
            }
        });
    };

    // TODO Вынести в общие utils (+RangeGroup component)
    const formattedNumber = new Intl.NumberFormat('ru-RU');
    const getPlaceholderText = (value: number | undefined, isCurrency?: boolean): string => {
        if (value === null || value === undefined) return '';
        if (isCurrency) return formatCurrencyRub(value);
        return formattedNumber.format(value);
    };

    function getNumberFromStringValue(stringValue: string | undefined) {
        if (stringValue === null || stringValue === undefined) return undefined;
        let value: number | undefined = parseInt(stringValue.replace(/\s+/g, ''), 10);
        if (Number.isNaN(value)) value = undefined;
        return value;
    }

    return (
        <div className={b()}>
            <div className={b('filters-container')}>
                <CustomRangeDatePicker
                    className={b('date-order')}
                    filters={filters}
                    setFilters={setFilters}
                    rangeKey="createdAtRange"
                />

                <CustomRangeDatePicker
                    filters={filters}
                    setFilters={setFilters}
                    className={b('date-delivery')}
                    rangeKey="deliveryDateRange"
                />

                <CustomSelect
                    placeholder="Статус"
                    size="m"
                    hasClear
                    multiple
                    options={filters.statuses}
                    className={b('status')}
                    onApply={handleApplyStatusFilter}
                    onCancel={() => console.log('Отмена')}
                    onReset={() => handleApplyStatusFilter([])}
                    isStatusSelect={true}
                    filters={filters}
                    hasCounter
                />

                <CustomSelect
                    placeholder="Реф №"
                    size="m"
                    hasClear
                    filterable
                    options={filters.refs}
                    onApply={handleRefsChange}
                    className={b('ref')}
                    onCancel={() => console.log('Отмена')}
                    onReset={() => handleRefsChange([])}
                    filters={filters}
                />

                <CustomSelect
                    placeholder="Бренд"
                    size="m"
                    hasClear
                    filterable
                    multiple
                    options={filters.brands}
                    className={b('brand')}
                    onApply={handleApplyBrandsFilter}
                    onCancel={() => console.log('Отмена')}
                    onReset={() => handleApplyBrandsFilter([])}
                    filters={filters}
                    hasCounter
                />

                <CustomSelect
                    placeholder="Артикул"
                    size="m"
                    hasClear
                    multiple
                    filterable
                    options={filters.articles}
                    className={b('article')}
                    onApply={handleArticlesChange}
                    onReset={() => handleArticlesChange([])}
                    onCancel={() => console.log('Отмена')}
                    filters={filters}
                    hasCounter
                />

                <div className={b('price-block')}>
                    <TextInput
                        label="Цена:"
                        placeholder={'от\xa0' + getPlaceholderText(filters.price?.min, true)}
                        value={
                            filters.price?.from !== undefined
                                ? formattedNumber.format(filters.price.from)
                                : ''
                        }
                        onUpdate={handlePriceFromChange}
                    />
                    <TextInput
                        placeholder={'до\xa0' + getPlaceholderText(filters.price?.max, true)}
                        value={
                            filters.price?.to !== undefined
                                ? formattedNumber.format(filters.price.to)
                                : ''
                        }
                        onUpdate={handlePriceToChange}
                    />
                </div>

                <div className={b('sum-block')}>
                    <TextInput
                        label="Сумма:"
                        placeholder={'от\xa0' + getPlaceholderText(filters.sum?.min, true)}
                        value={
                            filters.sum?.from !== undefined
                                ? formattedNumber.format(filters.sum.from)
                                : ''
                        }
                        onUpdate={handleSumFromChange}
                    />
                    <TextInput
                        placeholder={'до\xa0' + getPlaceholderText(filters.sum?.max, true)}
                        value={
                            filters.sum?.to !== undefined
                                ? formattedNumber.format(filters.sum.to)
                                : ''
                        }
                        onUpdate={handleSumToChange}
                    />
                </div>

                <div className={b('comment')}>
                    <Text variant={'subheader-1'} color={'secondary'}>
                        Комментарий
                    </Text>
                    <Switch
                        size="m"
                        checked={filters.onlyWithComments}
                        onUpdate={handleOnlyWithCommentsChange}
                    ></Switch>
                </div>

                <div></div>
            </div>

            <div className={b('actions-container')}>
                <Button size="m" className={b('button-save')} onClick={saveFilters}>
                    <Text>Сохранить</Text>
                </Button>

                <Button size="m" onClick={handleResetAllClick}>
                    <Icon data={TrashBin} />

                    <Text>Сбросить все</Text>
                </Button>
            </div>
        </div>
    );
};
