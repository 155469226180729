import {FiltersModel, SearchModel, SortOption} from '@features/search/interfaces';
import {getFiltered, getSorted} from '@features/search/services';

export function getFilteredAndSorted(
    sourceResults: SearchModel,
    filters: FiltersModel,
    sortOptions: SortOption[],
): SearchModel {
    const newSearchModel = {
        searched: [],
        original: [],
        analogs: [],
    } as SearchModel;

    const activeSort = sortOptions.filter((x) => x.isActive);
    const sortOption = activeSort.length !== 1 ? null : activeSort[0];

    newSearchModel.searched = getSorted(getFiltered(sourceResults.searched, filters), sortOption);
    newSearchModel.original = getSorted(getFiltered(sourceResults.original, filters), sortOption);
    newSearchModel.analogs = getSorted(getFiltered(sourceResults.analogs, filters), sortOption);

    return newSearchModel;
}
