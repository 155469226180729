import {Profile} from '../app/Providers/ProfileProvider/type'

export const initialStateProfile: Profile = {
    user: {
        name: 'Константинопольский Константин Константинович',
        email: 'blago@zapnsk.ru',
        phone: 89132580535,
    },
    notification: {
        telegram: false,
        email: false,
        sms: false,
    },
    clients: [{
        name: '0 ОЛ ТЕСТОВЫЙ для СП',
        address: '630033, Новосибирская обл, Новосибирск г, Оловозаводская ул, дом № 47',
        selfpickup: true,
    }],
    levelprice: {
        enabled: true,
        value: 50,
        visibility: {
            searchpage: true,
            basketpage: false
        }
    },
    contacts: {
        store: [{
            address: '630033, Новосибирская обл, Новосибирск г, Оловозаводская ул, дом № 47',
            //не понятно как будет передаваться
            worktime: 'Пн-пт: 09:00-20:0 Сб: 10:00-17:0 Вс: 10:00-15:00'
        }],
        staff: {
            manager: {
                name: 'Зорин Алексей Сергеевич',
                phone: '+7(965)823-88-2',
                email: 'zorin@emexnsk.ru'
            },
            mop: {
                name: 'Морозова Виктория Валерьевна',
                phone: '+7(965)823-93-43',
                email: 'morozova@zapnsk.ru'
            },
            supervisor: {
                name: 'Березовский Александр Игоревич',
                phone: '+7(953)879-49-07',
                email: 'BerezovskijA@zapnsk.ru'
            }
        }
    }
}