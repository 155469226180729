import {
    FilterBrandModel,
    FilterRangeModel,
    FiltersModel,
    ProposalModel,
    SearchModel,
    SearchRequest,
    SectionItemModel
} from '@features/search/interfaces';
import {ArticleInfoModel} from '@components';
import {createProposal} from '@utils/createProposal';
import {Proposal} from '@services/user-gw';
import {BasketItemModel} from '@features/basket/interfaces';
import {PriceLevelSettings} from 'app/priceLevelSettings';

export function processSearchApiResults({
    searchResults,
    searchRequest,
    basketMap,
    settings
}: {
    searchResults: Proposal[][];
    searchRequest: SearchRequest;
    basketMap: Map<string | undefined, BasketItemModel>;
    settings: PriceLevelSettings;
}): [SearchModel, FiltersModel] {
    const brandsMap = new Map<string, FilterBrandModel>();

    const searchedItems = new Map<string, SectionItemModel>();
    const originalItems = new Map<string, SectionItemModel>();
    const analogItems = new Map<string, SectionItemModel>();

    const priceRange: FilterRangeModel = {};
    const deliveryDayRange: FilterRangeModel = {};
    const ratingRange: FilterRangeModel = {};
    const quantityRange: FilterRangeModel = {};

    for (let generation = 0; generation < searchResults.length; generation++) {
        const genX = searchResults[generation];
        genX.forEach((x) => {
            const brand = brandsMap.get(x.brandName);
            if (!brand)
                brandsMap.set(x.brandName, {
                    brandName: x.brandName,
                    minPrice: x.price,
                    isSelected: false,
                });
            else if (brand.minPrice > x.price)
                brandsMap.set(x.brandName, {
                    brandName: x.brandName,
                    minPrice: x.price,
                    isSelected: false,
                });

            const itemKey = x.brandName + '::' + x.article;

            const header = {
                article: x.article,
                articleName: x.articleName,
                brand: x.brand,
                brandName: x.brandName,
            } as ArticleInfoModel;

            const basketItem = basketMap.get(x.basketItemId);
            const proposal = createProposal(x, generation, basketItem);

            let currentCollection: Map<string, SectionItemModel>;
            if (x.brandName === searchRequest.brand && x.article === searchRequest.article)
                currentCollection = searchedItems;
            else if (x.brandName === searchRequest.brand) currentCollection = originalItems;
            else currentCollection = analogItems;

            const item = currentCollection.get(itemKey);
            if (item) item.proposals.push(proposal);
            else currentCollection.set(itemKey, {key: itemKey, header, proposals: [proposal]});

            calcRanges(proposal);

            function calcRanges(proposalParam: ProposalModel) {
                calcRange(priceRange, proposalParam.price);
                calcRange(deliveryDayRange, proposalParam.deliveryDays);
                calcRange(ratingRange, proposalParam.rating);
                calcRange(quantityRange, proposalParam.quantity);
            }

            /*eslint no-param-reassign: ["error", { "props": false }]*/
            function calcRange(range: FilterRangeModel, value: number) {
                if (range.min === undefined || range.min > value) range.min = value;
                if (range.max === undefined || range.max < value) range.max = value;
            }
        });
    }

    return [
        {
            searched: Array.from(searchedItems.values()),
            original: Array.from(originalItems.values()),
            analogs: Array.from(analogItems.values()),
        } as SearchModel,
        {
            brands: Array.from(brandsMap.values()),
            priceRange: priceRange,
            deliveryDayRange: deliveryDayRange,
            quantityRange: quantityRange,
            ratingRange: ratingRange,
            isOfficialDealer: false,
            isReturnPossible: false,
            priceLevel: settings.enabled,
        } as FiltersModel];
}