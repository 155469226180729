import React, {useState} from 'react';
import block from 'bem-cn-lite';
import {Tooltip} from '@gravity-ui/uikit';


import './TooltipCopy.scss';

const b = block('tooltip-container');

interface ContactProps {
    children: React.ReactNode;
    copyText: string;
}

export const TooltipCopy: React.FC<ContactProps> = ({children, copyText}) => {
    const [openTooltip, setOpenTooltip] = useState(false);

    return (
        <Tooltip
            content="Скопировано"
            placement="top-end"
            disabled={!openTooltip}
            openDelay={0}
            contentClassName={b('tooltip')}
            className={b('')}
        >
            <div
                className={b('copy')}
                onClick={() => {
                    setOpenTooltip(true);
                    navigator.clipboard.writeText(copyText);
                    setTimeout(() => setOpenTooltip(false), 1000);
                }}
            >
                {children}
            </div>
        </Tooltip>
    );
};