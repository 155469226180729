const KEY = 'at.ug.counters';

class Storage {
    set = (counters: Record<string, number>) =>
        localStorage.setItem(KEY, JSON.stringify(counters || {}));
    get = () => JSON.parse(localStorage.getItem(KEY) || '{}') || {};
    clear = () => localStorage.removeItem(KEY);
}

export const countersStorage = new Storage();
