import block from 'bem-cn-lite';
import {Text} from '@gravity-ui/uikit';
import {CaretDown, CaretUp} from '@gravity-ui/icons';
import {useContext, useMemo} from 'react';
import {AppContext} from 'app/context';
import {useMediaQuery} from 'react-responsive';

import './Header.scss';

const b = block('header-orders-items');

interface ColumnMapping {
    className: string;
    width: string;
    header: React.ReactNode;
}

interface HeaderProps {
    sortOption: {property: string; direction: 'asc' | 'desc'} | null;
    onSortChange: (field: string) => void;
}

export const Header: React.FC<HeaderProps> = ({sortOption, onSortChange}) => {
    const {state} = useContext(AppContext);
    const {config} = state.orders;
    const {itemsChecked, itemsOrder} = config;

    const isSmallScreen = useMediaQuery({query: '(max-width: 1440px)'});

    const getIconColor = (field: string) => {
        return sortOption?.property === field ? '#BD8E4B' : 'inherit';
    };

    const renderCaretIcon = (field: string) => {
        if (sortOption?.property === field) {
            return sortOption.direction === 'asc' ? (
                <CaretUp style={{color: getIconColor(field)}} />
            ) : (
                <CaretDown style={{color: getIconColor(field)}} />
            );
        } else {
            return <CaretUp style={{color: getIconColor(field)}} />;
        }
    };

    const columnWidths: Record<number, number> = useMemo(() => {
        return isSmallScreen
            ? {
                  1: 98,
                  2: 119,
                  3: 101,
                  4: 124,
                  5: 80,
                  6: 176,
                  7: 82,
                  8: 100,
                  9: 100,
                  10: 128
              }
            : {
                  1: 98,
                  2: 119,
                  3: 136,
                  4: 116,
                  5: 68,
                  6: 241,
                  7: 82,
                  8: 124,
                  9: 154,
                  10: 148
              };
    }, [isSmallScreen]);

    const columnMappings: Record<number, ColumnMapping> = useMemo(
        () => ({
            1: {
                className: b('block'),
                width: `${columnWidths[1]}px`,
                header: (
                    <div className={b('date-order')} onClick={() => onSortChange('date')}>
                        <Text variant="subheader-2">
                            Дата
                            <br />
                            заказа
                        </Text>
                        {renderCaretIcon('date')}
                    </div>
                )
            },
            2: {
                className: b('block'),
                width: `${columnWidths[2]}px`,
                header: (
                    <div
                        className={b('date-delivery')}
                        onClick={() => onSortChange('delivery-date')}
                    >
                        <Text variant="subheader-2">
                            Дата
                            <br />
                            доставки
                        </Text>
                        {renderCaretIcon('delivery-date')}
                    </div>
                )
            },
            3: {
                className: b('block'),
                width: `${columnWidths[3]}px`,
                header: (
                    <div className={b('status')} onClick={() => onSortChange('status-date')}>
                        <Text variant="subheader-2">Статус</Text>
                        {renderCaretIcon('status-date')}
                    </div>
                )
            },
            4: {
                className: b('block'),
                width: `${columnWidths[4]}px`,
                header: (
                    <div className={b('ref')} onClick={() => onSortChange('ref')}>
                        <Text variant="subheader-2">Реф №</Text>
                        {renderCaretIcon('ref')}
                    </div>
                )
            },
            5: {
                className: b('block', b('bages')),
                width: `${columnWidths[5]}px`,
                header: null
            },
            6: {
                className: b('block', b('name')),
                width: `${columnWidths[6]}px`,
                header: <Text variant="subheader-2">Наименование</Text>
            },
            7: {
                className: b('block', b('quantity')),
                width: `${columnWidths[7]}px`,
                header: (
                    <>
                        <Text variant="subheader-2">
                            Кол-во,
                            <br />
                            шт.
                        </Text>
                    </>
                )
            },
            8: {
                className: b('block'),
                width: `${columnWidths[8]}px`,
                header: (
                    <div className={b('price')} onClick={() => onSortChange('price')}>
                        <Text variant="subheader-2">Цена</Text>
                        {renderCaretIcon('price')}
                    </div>
                )
            },
            9: {
                className: b('block'),
                width: `${columnWidths[9]}px`,
                header: (
                    <div className={b('sum')} onClick={() => onSortChange('sum')}>
                        <Text variant="subheader-2">Сумма заказа</Text>
                        {renderCaretIcon('sum')}
                    </div>
                )
            },
            10: {
                className: b('block', b('comment')),
                width: `${columnWidths[10]}px`,
                header: <Text variant="subheader-2">Комментарий</Text>
            },
            11: {
                className: b('block', b('aux-controls')),
                width: '88px',
                header: null
            }
        }),
        [b, columnWidths, sortOption]
    );

    const visibleColumns = itemsOrder.filter((id) => itemsChecked[id]);
    const hiddenColumns = itemsOrder.filter((id) => !itemsChecked[id]);

    let adjustedColumnMappings: Record<number, ColumnMapping> = {...columnMappings};
    if (visibleColumns.includes(6)) {
        const extraWidth = hiddenColumns.reduce((sum, id) => sum + columnWidths[id], 0);
        adjustedColumnMappings[6].width = `${columnWidths[6] + extraWidth}px`;
    }

    const gridTemplateColumns = visibleColumns.map((id) => adjustedColumnMappings[id].width);
    gridTemplateColumns.push(adjustedColumnMappings[11].width);

    return (
        <div className={b()} style={{gridTemplateColumns: gridTemplateColumns.join(' ')}}>
            {visibleColumns.map((id) =>
                adjustedColumnMappings[id].header ? (
                    <div key={id} className={adjustedColumnMappings[id].className}>
                        {adjustedColumnMappings[id].header}
                    </div>
                ) : (
                    <div key={id} className={adjustedColumnMappings[id].className} />
                )
            )}
            <div className={adjustedColumnMappings[11].className} />
        </div>
    );
};
