import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import block from 'bem-cn-lite';
import {Icon, Text} from '@gravity-ui/uikit';
import {Check, Grip} from '@gravity-ui/icons';

import './Items.scss';

const b = block('dnd-item');

interface ItemsProps {
    id: number;
    text: string;
    isChecked: boolean;
    toggleCheck: () => void;
}

export const Items: React.FC<ItemsProps> = ({id, text, isChecked, toggleCheck}) => {
    const {attributes, listeners, setNodeRef, transform, transition, active} = useSortable({
        id: id
    });

    const isDragging = active?.id === id;

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: active ? 'grabbing' : 'grab'
    };

    return (
        <div
            style={style}
            ref={setNodeRef}
            {...attributes}
            {...listeners}
            onClick={toggleCheck}
            className={`${b()} ${isDragging ? `${b()}--dragging` : ''}`}
        >
            <div style={{touchAction: 'none'}} className={b('drag-container')}>
                <Icon data={Grip} className={b('drag-icon')} />

                <div
                    className={b('check-container')}
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleCheck();
                    }}
                >
                    {isChecked && <Icon data={Check} className={b('check-icon')} />}
                </div>

                <Text variant="body-1">{text}</Text>
            </div>
        </div>
    );
};
