import React from 'react';
import {useNavigate} from 'react-router-dom';
import block from 'bem-cn-lite';
import {StoreAddress, ManagerInfo} from '@features/profile';

import './ContactsPage.scss';
//TODO при увеличение складов переделать стили в гриды


const b = block('contacts');

export const ContactsPage: React.FC = () => {
    return (
        <div className={b()}>
            <StoreAddress />
            <ManagerInfo />
        </div>
    );
};
