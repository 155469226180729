import React from 'react';
import block from 'bem-cn-lite';
import {Progress} from '@gravity-ui/uikit';
import './Rating.scss';

const b = block('rating');

export interface RatingProps {
    value: number;
    className: string;
}

export const Rating: React.FC<RatingProps> = ({className, value}) => {

    const rank = (value: number): string => {
        if (!value) {
            return '—'
        } else return `${value} %`
    }

    const progressValue = value === 0 ? NaN : value;
    const progressText = rank(value);

    return (
        <div className={b(null, className)}>
            <Progress
                className={b('progress')}
                size="m"
                value={progressValue}
                text={progressText}
                colorStops={[
                    { theme: 'danger', stop: 44 },
                    { theme: 'warning', stop: 84 },
                    { theme: 'success', stop: 100 },
                ]}
            ></Progress>
        </div >
    );
};
