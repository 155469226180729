import {ProposalModel} from '@features/search/interfaces';
import {Proposal} from '@services/user-gw';
import {BasketItemModel} from '@features/basket/interfaces';

export const createProposal = (x: Proposal, generation: number, b?: BasketItemModel): ProposalModel => {
    return {
        id: `${x.id}`,
        deliveryDate: new Date(Date.parse(x.delivery?.expected.client.date)),
        deliveryDays: x.delivery?.expected.client.days,
        isOfficialDealer: x.flags['official'],
        isReturnPossible: x.returnCondition !== 'impossible',
        multiplicity: x.multiplicity === 0 ? 1 : x.multiplicity,
        price: x.price,
        quantity: x.quantity,
        rating: x.rating || 0,
        generation: generation,
        basketItem: b
    };
};
