import {FiltersModel} from '@features/search/interfaces';
import {PriceLevelSettings} from 'app/priceLevelSettings';

export function getDefaultFilters(settings: PriceLevelSettings): FiltersModel {
    return {
        brands: [],
        deliveryDayRange: {},
        priceRange: {},
        quantityRange: {},
        ratingRange: {},
        isOfficialDealer: false,
        isReturnPossible: false,
        priceLevel: settings.enabled,
    };
}