import React, {SyntheticEvent, useEffect, useState, useContext} from 'react';
import block from 'bem-cn-lite';
import {Text, Icon, Checkbox} from '@gravity-ui/uikit';
import {House, FileText} from '@gravity-ui/icons';
import {ProfileContext} from 'app/Providers/ProfileProvider/ProfileProvider';
import './UserAddress.scss';

const b = block('user-address-block');

export const UserAddress: React.FC = () => {
    const {stateProfile: {clients}, setStateProfile} = useContext(ProfileContext);

    return (<>
        {clients.map((client, index) => (
            <div className={b()} key={index}>
                <Text variant='header-1'>Компания</Text>
                <Text className={b('paragraph')} variant='body-2'>
                    <Icon className={b('icon')} data={FileText} />{client.name}</Text>
                <Text className={b('paragraph')} variant='body-2'>
                    <Icon className={b('icon')} data={House} />{client.address}</Text>
                <Checkbox size='l' className={b('checkbox')} checked={client.selfpickup} disabled><span>Самовывоз</span></Checkbox>
            </div>
        ))}
    </>
    );
};
