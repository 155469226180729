import React, {SyntheticEvent, useEffect, useState, useContext} from 'react';
import block from 'bem-cn-lite';
import {Text, Icon, Checkbox} from '@gravity-ui/uikit';
import {House, Clock, MapPin} from '@gravity-ui/icons';
import {ProfileContext} from 'app/Providers/ProfileProvider/ProfileProvider';
import './StoreAddress.scss';

const b = block('store-address');

export const StoreAddress: React.FC = () => {
    const {stateProfile: {contacts}, setStateProfile} = useContext(ProfileContext);

    return (<>
        {contacts.store.map((element, index) => (
            <div className={b()} key={index + 1}>
                <Text variant='header-1'>Склад</Text>
                <Text className={b('paragraph')} variant='body-2'>
                    <Icon className={b('icon')} data={MapPin} />{element.address}</Text>
                <div className={b('work-time-container')}>
                    <Icon className={b('icon')} data={Clock} />
                    <div className={b('work-time-content')}>
                        <Text className={b('content')} variant='body-2'>{element.worktime}</Text>
                        {/* <Text className={b('content')} variant='body-2'>Пн-пт: 09:00-20:00</Text>
                        <Text className={b('content')} variant='body-2'>Сб: 10:00-17:00</Text>
                        <Text className={b('content')} variant='body-2'>Вс: 10:00-15:00</Text> */}
                    </div>
                </div>
            </div>
        ))}
    </>
    );
};
