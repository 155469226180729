import React, {useContext, useEffect, useState} from 'react';
import block from 'bem-cn-lite';
import {AppContext} from '../../app/context';
import {Navigate, useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {Link, Modal, Text, TextInput, useToaster} from '@gravity-ui/uikit';
import {Button} from '@components/shared/button';
import {CApi} from '@services/api';
import {ArrowShapeTurnUpLeft} from '@gravity-ui/icons';
import KeysPng from '@assets/keys.png';

import './RemindPass.scss';

const b = block('remind-pass-page');

interface RemindPassForm {
    login: string;
    email: string;
}

export const RemindPass: React.FC = () => {
    const appCtx = useContext(AppContext);

    if (appCtx.state.token) {
        return <Navigate to="/home" />;
    }

    const [done, setDone] = useState(false)
    const [email, setEmail] = useState('')

    return (
        <Modal open keepMounted className={b()}>
            {
                done ? <Done email={email} /> : <Init setDone={setDone} setEmail={setEmail} />
            }
        </Modal>
    );
};

interface InitProps {
    setDone: (done: boolean) => void;
    setEmail: (email: string) => void;
}

const Init: React.FC<InitProps> = ({setDone, setEmail}) => {
    const appCtx = useContext(AppContext);
    const navigateTo = useNavigate();
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        setError,
    } = useForm<RemindPassForm>({defaultValues: {login: '', email: ''}})

    const [showToast, setShowToast] = useState(false);

    const onSubmit = () => {
        appCtx.emit('increment', {name: 'remind_pass_attempt'});

        setLoading(true);
        CApi().resendPassword.resendPasswordCreate(
            {
                ...getValues(),
            },
        ).then(() => {
            appCtx.emit('clear_cnt', {name: 'remind_pass_attempt'});
            setLoading(false);
            setEmail(getValues('email'));
            setDone(true);
        }).catch(() => {
            if (appCtx.state.counters['remind_pass_attempt'] >= 5) {
                setShowToast(true);
            }

            setLoading(false);
            setError('login', {message: 'Неправильный логин или e-mail'});
            setError('email', {message: 'Неправильный логин или e-mail'});
        })
    }

    const { add } = useToaster();
    useEffect(() => {
        if (showToast && appCtx.state.counters['remind_pass_attempt'] >= 5) {
            add({
                name: 'remind_pass_attempt',
                title: 'Неверные данные',
                theme: 'info',
                content: (
                    <>
                        <Text variant="body-2">
                            Вы более 5 раз ввели неверные данные. Свяжитесь с менеджером +7(383)375-16-62
                        </Text>
                        <Button size="xl" onClick={() => navigateTo('/')} design="white">
                            На главную
                        </Button>
                    </>
                ),
                autoHiding: false,
                isClosable: true,
            });
        }
    }, [showToast]);

    return (
        <>
            <Text variant="body-2">
                <Link view="normal" href="#" onClick={() => navigateTo('/login')} className={b('link-back') + ' ' + b('like-old-link')}>
                    <ArrowShapeTurnUpLeft />
                    &nbsp;Назад
                </Link>
            </Text>
            <Text variant="header-2">
                Восстановление пароля
            </Text>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className={b('input')}>
                    <Text variant="body-2">Логин</Text>
                    <TextInput
                        size="xl"
                        {...register("login", {
                            required: 'Обязательно',
                        })}
                        errorMessage={errors.login && errors.login.message}
                        errorPlacement="outside"
                        validationState={errors.login && 'invalid'}
                    />
                </div>
                <div className={b('input')}>
                    <Text variant="body-2">Email</Text>
                    <TextInput
                        type="email"
                        size="xl"
                        {...register("email", {
                            required: 'Обязательно',
                            pattern: {
                                value: new RegExp('^\\S+@\\S+\\.\\S+$'),
                                message: 'Некорректный email',
                            }
                        })}
                        errorMessage={errors.email && errors.email.message}
                        errorPlacement="outside"
                        validationState={errors.email && 'invalid'}
                    />
                </div>
                <div className={b('input')}>
                    <Button type="submit" size="xl" loading={loading}>Отправить</Button>
                </div>
            </form>
        </>
    );
};

interface DoneProps {
    email: string;
}

const Done: React.FC<DoneProps> = ({email}) => {
    const navigateTo = useNavigate();

    return (
        <>
            <Text variant="body-2">
                <img src={KeysPng} width="148px" alt={'keys'}/>
            </Text>
            <Text variant="header-2">
                Отправили на почту
            </Text>
            <Text variant="body-2">
                Письмо на&nbsp;
                <Link href={"mailto:"+email} className={b('like-old-link')}>
                    {email}
                </Link>
                &nbsp;придет в течение 5 минут. Если письма нет, пожалуйста, обратитесь к вашему менеджеру.
            </Text>
            <Text variant="body-2">
                <Button size={'xl'} onClick={() => navigateTo('/login')} design="gray" className={b('link-back')}>
                    <ArrowShapeTurnUpLeft />
                    &nbsp;&nbsp;&nbsp;&nbsp;К авторизации
                </Button>
            </Text>
        </>
    );
};
