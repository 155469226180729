import {jwtDecode} from 'jwt-decode';

export interface IUser {
    login: string;
    displayName: string;
    email: string;
    phone: number;
    client: string;
    clientName: string;
    clientRole: string;
    permissions: Permissions;
}

interface Permissions {
    [key: string]: boolean;
}

export const HasPermission = (user: IUser, permission: string): boolean => {
    if (!(permission in user.permissions)) {
        return false;
    }

    return user.permissions[permission];
};

export const userFromToken = (token: string): IUser => jwtDecode<IUser>(token);

const USER_KEY = 'at.ug.user';

class UserStorage {
    set = (user: IUser) => {
        localStorage.setItem(USER_KEY, JSON.stringify(user));
    };

    get = (): IUser | null => {
        const userString = localStorage.getItem(USER_KEY);
        if (!userString) {
            return null;
        }

        return JSON.parse(userString);
    };

    clear = () => {
        localStorage.removeItem(USER_KEY);
    };
}

export const userStorage = new UserStorage();
