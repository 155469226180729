import React from 'react';
import block from 'bem-cn-lite';
import {SortSelector} from './components/SortSelector';
import {SortOption, SortProperty} from '@features/search/interfaces';

import './SectionItemsHeader.scss';

const b = block('section-items-header');

export interface SectionItemsHeaderProps {
    sortOptions: SortOption[];
    onSortChanged?: (sortOptions: SortOption[]) => void;
}

export const SectionItemsHeader: React.FC<SectionItemsHeaderProps> = ({
    sortOptions,
    onSortChanged
}) => {

    function handleSortChanged(sortOption: SortOption) {
        const newSortOptions = sortOptions.map((x) => {
            return x.property !== sortOption.property
                ? {...x, direction: 'asc', isActive: false}
                : {...x, direction: sortOption.direction, isActive: true};
        }) as SortOption[];
        if (onSortChanged) onSortChanged(newSortOptions);
    }

    return (
        <div className={b()}>
            <div className={b('title')}>Бренд/артикул</div>
            <div className={b('proposal-header')}>
                <div className={b('icons')}></div>
                <SortSelector
                    className={b('rating')}
                    title={'Рейтинг'}
                    sortOption={sortOptions[SortProperty.Rating]}
                    defaultDirection={'desc'}
                    onSortChanged={handleSortChanged}
                />
                <SortSelector
                    className={b('quantity')}
                    title={'Наличие'}
                    sortOption={sortOptions[SortProperty.Quantity]}
                    defaultDirection={'desc'}
                    onSortChanged={handleSortChanged}
                />
                <SortSelector
                    className={b('delivery-days')}
                    title={'Срок, дн'}
                    sortOption={sortOptions[SortProperty.DeliveryDays]}
                    defaultDirection={'asc'}
                    onSortChanged={handleSortChanged}
                />
                <SortSelector
                    className={b('delivery-date')}
                    title={'К дате'}
                    sortOption={sortOptions[SortProperty.DeliveryDate]}
                    defaultDirection={'asc'}
                    onSortChanged={handleSortChanged}
                />
                <SortSelector
                    className={b('price')}
                    title={'Цена'}
                    sortOption={sortOptions[SortProperty.Price]}
                    defaultDirection={'asc'}
                    onSortChanged={handleSortChanged}
                />
                <div className={b('basket-controls')}></div>
            </div>
        </div>
    );
};
