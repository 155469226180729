import React, {useContext} from 'react';
import block from 'bem-cn-lite';
import './BasketControls.scss';
import {Button} from '@gravity-ui/uikit';
import {Check, ShoppingCart} from '@gravity-ui/icons';
import {QuantityInput} from '@components';
import {AppContext} from 'app/context';
import {CApi as userGwApi} from '@services/api';
import {useToast} from '@hooks';

const b = block('basket-controls');

export interface BasketControlsProps {
    className: string;
    proposalId: string;
    basketItemId?: string;
    quantity: number;
    multiplicity: number;
    maxQuantity: number;
}

export const BasketControls: React.FC<BasketControlsProps> = ({
    className,
    proposalId,
    basketItemId,
    quantity,
    multiplicity,
    maxQuantity
}) => {
    const {state, emit} = useContext(AppContext);
    const {showAddToCartErrorToast} = useToast();

    const [isLoading, setIsLoading] = React.useState(false);
    const [currentQuantity, setCurrentQuantity] = React.useState(quantity);
    const [currentBasketItemId, setCurrentBasketItemId] = React.useState<string | undefined>(basketItemId);

    const isAdded = !!currentBasketItemId;
    const buttonClass = isAdded ? 'basket-button-added' : 'basket-button';

    // Обработчик изменения количества товара
    const handleQuantityChange = (newQuantity: number) => {
        setCurrentQuantity(newQuantity);

        // Проверяем есть ли предложение в корзине
        if (!currentBasketItemId) {
            return;
        }

        // Товар уже добавлен в корзину, нужно обновить количество на сервере

        // Используем комментарий из state.basket.items
        const currentItem = state.basket.items.find((item) => item.id === currentBasketItemId);
        const currentComment = currentItem?.comment || '';

        if (!currentItem) {
            console.error('basketItemId not found');
            return;
        }

        const updatedItem = {
            itemsCnt: newQuantity,
            comment: currentComment
        };

        userGwApi()
            .basket.basketPartialUpdate({[currentBasketItemId]: updatedItem})
            .then(() => {
                const updatedBasketItem = {
                    ...currentItem,
                    itemsCnt: newQuantity
                };

                // Рассчитываем новое значение totalAmount
                const oldTotalAmount = state.basket.summary.totalAmount || 0;
                const newTotalAmount =
                    oldTotalAmount -
                    currentItem.price * currentItem.itemsCnt +
                    currentItem.price * newQuantity;

                emit('updateBasket', {
                    basket: {
                        items: state.basket.items.map((item) =>
                            item.id === currentBasketItemId ? updatedBasketItem : item
                        ),
                        summary: {
                            totalAmount: newTotalAmount
                        }
                    }
                });
            })
            .catch((error) => {
                console.error('Ошибка обновления товара в корзине:', error);
            });
    };

    // Обработчик добавления товара в корзину
    const handleAddToBasket = () => {
        setIsLoading(true);

        const data = {
            [proposalId]: {
                itemsCnt: currentQuantity,
                comment: ''
            }
        };

        userGwApi()
            .basket.basketPartialUpdate(data)
            .then((response) => {
                const currentCounter = state.counters['basketItems'] || 0;
                const newCounterValue = currentCounter + 1;
                emit('updateCounter', {counterName: 'basketItems', value: newCounterValue});

                const basketItem = response.data.items.filter(x => x.id === proposalId)[0];

                const currentTotalAmount = state.basket.summary.totalAmount || 0;
                const newTotalAmount = currentTotalAmount + basketItem.price * quantity;

                emit('updateBasket', {
                    basket: {
                        items: [
                            ...state.basket.items.filter(
                                (item) =>
                                    !response.data.items.some(
                                        (resItem) => resItem.id === item.id
                                    )
                            ),
                            ...response.data.items
                        ],
                        summary: {
                            totalAmount: newTotalAmount
                        }
                    }
                });

                // Обновляем свойство basketItemId у proposal
                setCurrentBasketItemId(basketItem.id);
            })
            .catch((error) => {
                console.error('Error adding to basket', error);
                showAddToCartErrorToast();
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    // Обработчик удаления товара из корзины
    const handleDeleteFromBasket = () => {
        setIsLoading(true);

        if (!currentBasketItemId) {
            console.error('Cannot delete item without basketItemId');
            return;
        }

        const itemToDelete = {
            [currentBasketItemId]: {itemsCnt: 0}
        };

        userGwApi()
            .basket.basketPartialUpdate(itemToDelete)
            .then(() => {
                const currentItem = state.basket.items.find((item) => item.id === currentBasketItemId);
                if (!currentItem) {
                    console.error('Item not found in basket');
                    return;
                }

                const currentTotalAmount = state.basket.summary.totalAmount || 0;
                const newTotalAmount =
                    currentTotalAmount - currentItem.price * currentItem.itemsCnt;

                const currentCounter = state.counters['basketItems'] || 0;
                const newCounterValue = currentCounter - 1;
                emit('updateCounter', {counterName: 'basketItems', value: newCounterValue});

                const updatedItems = state.basket.items.filter((item) => item.id !== currentBasketItemId);

                emit('updateBasket', {
                    basket: {
                        items: updatedItems,
                        summary: {
                            totalAmount: newTotalAmount
                        }
                    }
                });

                setCurrentBasketItemId(undefined);
                setCurrentQuantity(multiplicity);
            })
            .catch((error) => {
                console.error('Error removing from basket', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleDecrease = isAdded && currentQuantity === multiplicity ? handleDeleteFromBasket : undefined;

    return (
        <div className={b(null, className)}>
            <QuantityInput
                value={currentQuantity}
                multiplicity={multiplicity}
                onChange={handleQuantityChange}
                maxQuantity={maxQuantity}
                onDecrease={handleDecrease}
            />
            <Button
                onClick={isAdded ? handleDeleteFromBasket : handleAddToBasket}
                size="m"
                className={b('button', buttonClass)}
                view="action"
                disabled={isLoading}
                loading={isLoading}
            >
                {isAdded ? <Check className={b('check-icon')} /> : <ShoppingCart className={b('cart-icon')} />}
            </Button>
        </div>
    );
};
