import { useState, useRef } from 'react';

function useHoverCard(delay = 200) {
    const [isVisible, setIsVisible] = useState(false);
    const hideTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const handleMouseEnter = () => {
        if (hideTimeoutRef.current) {
            clearTimeout(hideTimeoutRef.current);
            hideTimeoutRef.current = null;
        }
        setIsVisible(true);
    };

    const handleMouseLeave = () => {
        hideTimeoutRef.current = setTimeout(() => {
            setIsVisible(false);
        }, delay);
    };

    const hideUserCard = () => setIsVisible(false);

    return {
        isVisible,
        handleMouseEnter,
        handleMouseLeave,
        hideUserCard,
    };
}

export default useHoverCard;
