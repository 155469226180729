import React, {SyntheticEvent, useContext, useEffect, useState} from 'react';
import block from 'bem-cn-lite';
import {Alert, Button, Checkbox, Switch, Text, TextInput} from '@gravity-ui/uikit';
import {ProfileContext} from 'app/Providers/ProfileProvider/ProfileProvider';
import {Profile} from 'app/Providers/ProfileProvider/type';
import './LevelPrice.scss';
import {AppContext} from 'app/context';
import {PriceLevelSettings} from 'app/priceLevelSettings';

const b = block('level-price');

export const LevelPrice: React.FC = () => {
    const {state, emit} = useContext(AppContext);
    const settings = state.priceLevelSettings;

    const {stateProfile: {levelprice}, setStateProfile} = useContext(ProfileContext);

    const [stateSwitch, setStateSwitch] = useState(levelprice.enabled);
    const [stateInput, setStateInput] = useState(levelprice.value);
    const [stateCheckBox, setStateCheckBox] = useState<Profile['levelprice']['visibility']>(levelprice.visibility);
    const [stateBtn, setStateBtn] = useState(true);

    useEffect(() => {
        if (stateSwitch === levelprice.enabled && stateInput === levelprice.value &&
            stateCheckBox.searchpage === levelprice.visibility.searchpage &&
            stateCheckBox.basketpage === levelprice.visibility.basketpage) {
            setStateBtn(true);
        } else {
            setStateBtn(false);
        }
    }, [stateSwitch, stateInput, stateCheckBox, levelprice]);


    function handleClickSwitch() {
        setStateSwitch(prevState => !prevState);
    }

    function onChangeInput(value: string) {
        const valueClear = value.replace(' %', '');
        const regex = /^\d+$/;

        if (regex.test(valueClear) && valueClear.length <= 5) {
            setStateInput(parseInt(valueClear))
        }

    }

    function handleClickCheckbox(e: SyntheticEvent) {
        const id = e.currentTarget.id as keyof Profile['levelprice']['visibility']
        setStateCheckBox((prevState) => ({
            ...prevState,
            [id]: !prevState[id]
        }))
    }

    function handleClickSubmit() {
        //TODO нужно куда то отправить апи
        emit('updatePriceLevelSettings', {
            settings: {
                ...settings,
                enabled: stateSwitch,
                displayInBasket: stateCheckBox.basketpage,
                displayInSearchResults: stateCheckBox.searchpage,
                markupPercentage: stateInput
            } as PriceLevelSettings
        });

        setStateProfile({
            levelprice: {
                ...levelprice,
                enabled: stateSwitch,
                value: stateInput,
                visibility: stateCheckBox
            }
        })
    }


    return (
        <div className={b()}>
            <div className={b('header-container')}>
                <Text variant='header-1'>Уровень цен</Text>
                <Switch onChange={handleClickSwitch} checked={stateSwitch} />
            </div>
            <TextInput size='l' className={b('input')} placeholder='введите % наценки' disabled={!stateSwitch} onUpdate={onChangeInput} value={`${stateInput.toString()} %`} />
            <Text variant='body-2' className={b('paragraph')}>Отображать наценку в разделах:</Text>
            <Checkbox size='l' className={b('checkbox')} checked={stateCheckBox.searchpage} onChange={handleClickCheckbox} id='searchpage' disabled={!stateSwitch}><span>результаты поиска</span></Checkbox>
            <Checkbox size='l' className={b('checkbox')} checked={stateCheckBox.basketpage} onChange={handleClickCheckbox} id='basketpage' disabled={!stateSwitch}><span>корзина</span></Checkbox>
            <Button size='l' className={b('btn')} disabled={stateBtn} onClick={handleClickSubmit}>Применить</Button>
            <Alert className={b('alert')} view='outlined' theme='info' title='Как пользоваться' message='Краткая информация инструкция об использовании функции' />
        </div>
    );
};
