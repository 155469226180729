const formatNumber = new Intl.NumberFormat(
    'ru-RU',
    {
        style: 'currency',
        currency: 'RUB',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    },
);

const fractionNumber = new Intl.NumberFormat(
    'ru-RU',
    {
        style: 'currency',
        currency: 'RUB',
        minimumFractionDigits: 2
    },
);

export const formatCurrencyRub = (amount: number): string => {
    if (amount % 1 === 0)
        return formatNumber.format(amount).replace(/\B(?=(\d{3})+(?!\d))/g, '\xa0');
    else
        return fractionNumber.format(amount).replace(/\B(?=(\d{3})+(?!\d))/g, '\xa0');
}