import {SortOption, SortProperty} from '@features/search/interfaces';

export function getDefaultSortOptions(): SortOption[] {
    return [
        {
            property: SortProperty.Rating,
            direction: 'asc',
            isActive: false,
        },
        {
            property: SortProperty.Quantity,
            direction: 'asc',
            isActive: false,
        },
        {
            property: SortProperty.DeliveryDays,
            direction: 'asc',
            isActive: false,
        },
        {
            property: SortProperty.DeliveryDate,
            direction: 'asc',
            isActive: false,
        },
        {
            property: SortProperty.Price,
            direction: 'asc',
            isActive: true,
        },
    ];
}