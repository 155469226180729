import {Fragment, useEffect, useMemo, useState} from 'react';
import {Button, Select, SelectOption, Text, TextInput} from '@gravity-ui/uikit';
import block from 'bem-cn-lite';
import {Check} from '@gravity-ui/icons';

import './CustomSelect.scss';
import {FiltersModel, ISelectOption} from '@features/orders/interfaces';
import {getStatusIcon} from '@features/orders/components/OrdersFilters/components/CustomSelect/utils/getStatusIcon';

const b = block('custom-select-orders');

interface CustomSelectProps {
    placeholder: string;
    size: 'm' | 's' | 'l';
    hasClear: boolean;
    multiple?: boolean;
    filterable?: boolean;
    hasCounter?: boolean;
    options: ISelectOption[];
    className?: string;
    onApply?: (values: string[]) => void;
    onCancel?: () => void;
    onReset?: () => void;
    isStatusSelect?: boolean; // для определения цвета статуса в фильтре
    filters: FiltersModel;
}

export const CustomSelect: React.FC<CustomSelectProps> = ({
    placeholder,
    size,
    hasClear,
    multiple = false,
    filterable = false,
    hasCounter = false,
    options,
    className,
    onApply,
    onCancel,
    onReset,
    isStatusSelect = false,
    filters
}) => {
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [open, setOpen] = useState(false);
    const [filterValue, setFilterValue] = useState('');

    const selectedOptions = useMemo(() => {
        return options.filter((x) => x.isSelected).map((x) => x.value);
    }, [options]);

    useEffect(() => {
        if (JSON.stringify(selectedValues) !== JSON.stringify(selectedOptions)) {
            setSelectedValues(selectedOptions);
        }
    }, [selectedOptions]);

    const handleReset = () => {
        setSelectedValues([]);
        if (onReset) {
            onReset();
        }
    };

    const handleApply = () => {
        if (onApply) {
            onApply(selectedValues);
        }
        setOpen(false);
    };

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }
        setOpen(false);
    };

    function handleUpdate(value: string[]) {
        setSelectedValues(value);
        if (!multiple && onApply) onApply(value);
        if (!open && onApply) onApply(value);
    }

    const getLabel = () => {
        if (selectedValues.length > 1) {
            if (options === filters.statuses) return 'Статусы:';
            if (options === filters.brands) return 'Бренды:';
            if (options === filters.articles) return 'Артикулы:';
        }
        return undefined;
    };

    const renderSelectedOption = (option: SelectOption) => {
        const {data} = option;
        if (multiple && selectedValues.length > 1) {
            return <Fragment />;
        }
        return <span className="g-select-control__option-text">{data?.label}</span>;
    };

    return (
        <Select
            open={open}
            onOpenChange={(isOpen) => {
                setOpen(isOpen);
                if (!isOpen) {
                    setFilterValue('');
                }
            }}
            value={selectedValues}
            placeholder={placeholder}
            size={size}
            hasClear={hasClear}
            multiple={multiple}
            filterable={filterable}
            hasCounter={multiple && selectedValues.length > 1}
            className={`${className} ${multiple && selectedValues.length > 1 ? 'multiple-selected' : ''}`}
            onUpdate={handleUpdate}
            label={multiple && selectedValues.length > 1 ? getLabel() : undefined}
            renderSelectedOption={renderSelectedOption}
            renderEmptyOptions={() => <Text className={b('empty-options')}>Не найдено</Text>}
            renderPopup={({renderList, renderFilter}) => {
                const filteredOptions = options.filter((option) =>
                    option.label.toLowerCase().includes(filterValue.toLowerCase())
                );
                const hasOptions = filteredOptions.length > 0;

                return (
                    <div className={b()}>
                        {filterable && <div className={b('header-search')}>{renderFilter()}</div>}

                        {multiple && onReset && isStatusSelect === true && (
                            <div className={b('header-button')}>
                                <Button
                                    view="outlined"
                                    className={b('button-outlined')}
                                    size="m"
                                    onClick={handleReset}
                                >
                                    Сбросить все
                                </Button>
                            </div>
                        )}

                        {renderList()}

                        {hasOptions && (
                            <div className={b('footer-button')}>
                                {onCancel && (
                                    <Button
                                        size="l"
                                        onClick={handleCancel}
                                        className={b('button-cancel')}
                                        style={{
                                            width: onApply ? '50%' : 'auto'
                                        }}
                                    >
                                        Отмена
                                    </Button>
                                )}
                                {multiple && onApply && (
                                    <Button
                                        view="action"
                                        size="l"
                                        onClick={handleApply}
                                    >
                                        Применить
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                );
            }}
            renderFilter={(props) => (
                <TextInput
                    placeholder="Поиск"
                    value={props.value}
                    onChange={(event) => {
                        props.onChange(event.target.value);
                        setFilterValue(event.target.value);
                    }}
                    onKeyDown={props.onKeyDown}
                    controlRef={props.ref}
                    style={props.style}
                />
            )}
        >
            {options
                .filter((option) => option.label.toLowerCase().includes(filterValue.toLowerCase()))
                .map((option) => {
                    const isSelected = selectedValues.includes(option.value);

                    let textColor: 'primary' | 'positive-heavy' | 'danger-heavy' | 'misc-heavy' =
                        'primary';

                    if (isStatusSelect) {
                        switch (option.value) {
                            case 'Выдан':
                                textColor = 'positive-heavy';
                                break;
                            case 'Отказ':
                                textColor = 'danger-heavy';
                                break;
                            default:
                                textColor = 'misc-heavy';
                        }
                    }

                    return (
                        <Select.Option
                            key={option.value}
                            value={option.value}
                            text={option.label}
                            data={option}
                        >
                            <div
                                style={{
                                    paddingLeft: !multiple && !isSelected ? '30px' : '0'
                                }}
                            >
                                {!multiple && isSelected && (
                                    <Check
                                        style={{
                                            marginRight: '14px',
                                            color: 'rgba(189, 142, 75, 1)',
                                            verticalAlign: 'middle'
                                        }}
                                    />
                                )}

                                {isStatusSelect && getStatusIcon(option.value)}

                                <Text color={textColor}>{option.label}</Text>
                            </div>
                        </Select.Option>
                    );
                })}
        </Select>
    );
};
