import { AppState } from "app/context";

export const basketStorage = {
    get: (): AppState['basket'] | null => {
        const data = localStorage.getItem('basket');
        return data ? JSON.parse(data) : null;
    },
    set: (basket: AppState['basket']): void => {
        localStorage.setItem('basket', JSON.stringify(basket));
    },
    clear: (): void => {
        localStorage.removeItem('basket');
    },
};