import React, {useState} from 'react';
import block from 'bem-cn-lite';
import './Item.scss';
import {ProposalModel} from '@features/search/interfaces';
import {ArticleInfo, ArticleInfoModel} from '@components';
import {ChevronDown} from '@gravity-ui/icons';
import {getNounForm} from '@utils';
import {Proposal} from './components/Proposal';

const b = block('item');

export interface ItemProps {
    header: ArticleInfoModel;
    proposals: ProposalModel[];
}

export const Item: React.FC<ItemProps> = ({header, proposals}) => {
    const [showMoreToggled, setShowMoreToggled] = useState(false);
    const maxProposalsToShow = 3;
    const showMoreIsVisible = proposals.length > maxProposalsToShow && !showMoreToggled;
    const proposalsToShow = proposals.length <= maxProposalsToShow || showMoreToggled
        ? proposals
        : proposals.slice(0, maxProposalsToShow);
    const shorMoreLength = proposals.length - maxProposalsToShow;
    const showMoreNoun = getNounForm(shorMoreLength, ['предложение', 'предложения', 'предложений']);
    const showMoreText = showMoreToggled ? 'Скрыть' : 'Показать ещё';

    function handleShowMoreClick() {
        setShowMoreToggled((prev) => !prev);
    }

    return (
        <>
            <div className={b()}>
                <ArticleInfo className={b('item-header')} header={header} />
                <div className={b('proposals')}>
                    {proposalsToShow.map((proposal) => {
                        return (
                            <Proposal key={proposal.id} proposal={proposal} />
                        );
                    })}
                </div>
            </div>
            {showMoreIsVisible ? (
                <div className={b('show-more-container')} onClick={handleShowMoreClick}>
                    <span className={(b('show-more'))}>{showMoreText} {shorMoreLength} {showMoreNoun}</span>
                    <ChevronDown className={b('show-more-icon', {rotation: showMoreToggled})} />
                </div>) : null}
        </>
    );
};
