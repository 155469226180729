import React from 'react';
import block from 'bem-cn-lite';
import {Text, Tooltip} from '@gravity-ui/uikit';
import {ArrowsRotateRightSlash} from '@gravity-ui/icons';
import './ReturnBadge.scss';

const b = block('return-badge');

export interface ReturnBadgeProps {
    isReturnPossible?: boolean;
}

export const ReturnBadge: React.FC<ReturnBadgeProps> = ({ isReturnPossible }) => {
    
    if (isReturnPossible === false) {
        return (
            <Tooltip
                content={
                    <Text className={b('return-badge-tooltip-content')}>
                        Возврат невозможен
                    </Text>
                }
                placement="top-end"
                contentClassName={b('return-badge-tooltip')}
                className={b('return-badge-tooltip-container')}
                openDelay={0}
                closeDelay={0}
                disabled={false}
            >
                <div className={b()}>
                    <ArrowsRotateRightSlash />
                </div>
            </Tooltip>
        );
    }

    // Если value true или пустое (не задано), возвращаем пустой блок
    return <div className={b()} />;
};