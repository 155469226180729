import React from 'react';
import block from 'bem-cn-lite';
import {Checkbox, Text} from '@gravity-ui/uikit';
import './BasketItemsOut.scss';
import {Items} from './Items';
import {useBasket} from '@features/basket/hooks/useBasket';
import {BasketItemModel} from '@features/basket/interfaces';

const b = block('basket-items-out');

interface BasketItemsOutProps {
    itemsUnavailableOccurred: boolean;
    itemsCntExceedsQuantityOccurred: boolean;
    items: BasketItemModel[];
}

export const BasketItemsOut: React.FC<BasketItemsOutProps> = ({
    itemsUnavailableOccurred,
    itemsCntExceedsQuantityOccurred,
    items
}) => {
    const {handleSelectAllItemsOut, allCheckedItemsOut} = useBasket();

    return (
        <>
            <div className={b('header')}>
                <Text variant={'subheader-2'} color={'secondary'}>
                    Эти товары закончились
                </Text>

                <Checkbox
                    size={'l'}
                    className={b('select-all')}
                    checked={allCheckedItemsOut}
                    onChange={(event) => handleSelectAllItemsOut(event.target.checked)}
                >
                    <Text variant={'body-2'}>Выбрать всё</Text>
                </Checkbox>
            </div>

            <Items
                itemsUnavailableOccurred={itemsUnavailableOccurred}
                itemsCntExceedsQuantityOccurred={itemsCntExceedsQuantityOccurred}
                items={items}
            />
        </>
    );
};
