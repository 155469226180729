import {FC, RefObject, useEffect} from "react";
import {Button} from "@gravity-ui/uikit";
import block from 'bem-cn-lite';
import {ArrowUp} from '@gravity-ui/icons';
import './ScrollUpButton.scss';

const b = block('scroll-button');

interface ScrollUpBtnProps {
    targetRef?: RefObject<HTMLDivElement>;
    hiddenBtn: boolean;
    changeVisibility: (state: boolean) => void
}

//сколько прокрутить экранов чтобы появилась кнопка
const multiplierScroll = 2;

export const ScrollUpButton: FC<ScrollUpBtnProps> = ({targetRef, hiddenBtn, changeVisibility}) => {

    function handleClick() {
        if (targetRef) {
            targetRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
        } else window.scrollBy(0, -window.scrollY);
    }


    const handleScroll = () => {
        if (window.scrollY >= window.innerHeight * multiplierScroll) {
            changeVisibility(false);
        } else {
            changeVisibility(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <Button
            onClick={handleClick}
            view="raised"
            className={b({hidden: hiddenBtn})}>
            <ArrowUp />
        </Button>
    )
}