import React from 'react';
import block from 'bem-cn-lite';
import './Quantity.scss';
import {Text} from '@gravity-ui/uikit';

const b = block('quantity');

export interface QuantityProps {
    value: number;
    className: string;
    status?: string;
}

export const Quantity: React.FC<QuantityProps> = (props) => {
    const displayValue = props.status === 'unavailable' ? 0 : props.value;
    const color = displayValue === 0 ? 'danger' : 'primary';

    return (
        <Text variant={'body-2'} color={color} className={b() + ' ' + props.className}>
            {displayValue}
        </Text>
    );
};
