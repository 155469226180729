import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {SearchRequest} from '../interfaces';

const SEARCH_HISTORY_KEY = 'at.ug.search-history';

export function useSearchHistory(): [SearchRequest[], Dispatch<SetStateAction<SearchRequest[]>>] {
    const [searchHistory, setSearchHistory] = useLocalStorage<SearchRequest[]>(
        SEARCH_HISTORY_KEY,
        [],
    );

    return [searchHistory, setSearchHistory];
}

function useLocalStorage<T>(key: string, defaultValue: T) {
    const [value, setValue] = useState(() => {
        const storedValue = localStorage.getItem(key);
        if (storedValue) {
            return JSON.parse(storedValue);
        }
        return defaultValue;
    });

    useEffect(() => {
        if (value === undefined) return;
        localStorage.setItem(key, JSON.stringify(value));
    }, [value, key]);

    return [value, setValue];
}
