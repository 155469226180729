import {ReactNode, useState} from 'react';
import {ToasterProvider} from '@gravity-ui/uikit';
import {ToastVisibilityContext} from './ToastVisibilityContext';
import {CustomToaster} from './CustomToaster';

interface CustomToasterProviderProps {
    children: ReactNode;
}

export const CustomToasterProvider: React.FC<CustomToasterProviderProps> = ({children}) => {
    const [isToasterVisible, setIsToasterVisible] = useState(false);
    const [currentToastName, setCurrentToastName] = useState<string | null>(null);

    return (
        <ToasterProvider>
            <ToastVisibilityContext.Provider
                value={{
                    isToasterVisible,
                    setIsToasterVisible,
                    currentToastName,
                    setCurrentToastName
                }}
            >
                {children}
                <CustomToaster />
            </ToastVisibilityContext.Provider>
        </ToasterProvider>
    );
};
