import React, {useEffect, useRef, useState} from 'react';
import block from 'bem-cn-lite';
import {Text, Tooltip} from '@gravity-ui/uikit';

import './CommentWithTooltip.scss';

const b = block('orders-comment-tooltip');

interface CommentWithTooltipProps {
    comment: string;
}

export const CommentWithTooltip: React.FC<CommentWithTooltipProps> = ({comment}) => {
    const textRef = useRef<HTMLDivElement>(null);
    const [isOverflowed, setIsOverflowed] = useState(false);

    useEffect(() => {
        const el = textRef.current;
        if (el) {
            const isOverflowing =
                el.scrollHeight > el.clientHeight || el.scrollWidth > el.clientWidth;
            setIsOverflowed(isOverflowing);
        }
    }, [comment]);

    return isOverflowed ? (
        <Tooltip
            content={
                <Text variant={'body-1'} className={b('tooltip-comment-content')}>
                    {comment}
                </Text>
            }
            contentClassName={b('tooltip-comment')}
            className={b('tooltip-comment-container')}
            placement="top-start"
            openDelay={0}
            closeDelay={0}
        >
            <Text variant={'body-1'} className={b('text-comment')} ref={textRef}>
                {comment}
            </Text>
        </Tooltip>
    ) : (
        <Text variant={'body-1'} className={b('text-comment')} ref={textRef}>
            {comment}
        </Text>
    );
};
