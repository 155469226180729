import block from 'bem-cn-lite';
import './BasketItems.scss';
import {Header, Items} from './components';
import {BasketItemModel} from '@features/basket/interfaces';

const b = block('basket-items');

interface BasketItemsProps {
    sortOption: {property: string; direction: 'asc' | 'desc'} | null;
    items: BasketItemModel[];
    onSortChange: (field: string) => void;
}

export const BasketItems: React.FC<BasketItemsProps> = ({items, onSortChange, sortOption}) => {
    return (
        <div className={b()}>
            <Header sortOption={sortOption} onSortChange={onSortChange} />
            <Items items={items} />
        </div>
    );
};
