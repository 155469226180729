import {CircleInfo} from '@gravity-ui/icons';
import {Text, Tooltip} from '@gravity-ui/uikit';
import block from 'bem-cn-lite';
import {StatusIconMapper, StatusTextColorMapper} from './StatusConfig';
import {OrderItemModel} from '@features/orders/interfaces';

import './Status.scss';
import {formatDate} from '@utils';

const b = block('orders-status');

interface StatusProps {
    item: OrderItemModel;
}

export const Status: React.FC<StatusProps> = ({item}) => {
    const dates = formatDate(item.status.createdAt, ['dd.mm.yy', 'HH:MM']);

    return (
        <div className={b()}>
            <div className={b('status')}>
                <StatusIconMapper status={item.status.name} />
                <StatusTextColorMapper status={item.status.name} />
            </div>

            <div className={b('update-date')}>
                <div className={b('date')}>
                    <Text variant={'body-2'} color={'secondary'}>
                        {dates[0]}
                    </Text>

                    <Tooltip
                        content={<Text variant={'body-1'}>Последнее обновление статуса</Text>}
                        contentClassName={b('update-tooltip')}
                        className={b('update-tooltip-container')}
                        placement="top"
                        openDelay={0}
                        closeDelay={0}
                        disabled={false}
                    >
                        <div>
                            <CircleInfo className={b('update-icon')} />
                        </div>
                    </Tooltip>
                </div>

                <Text variant={'body-2'} color={'secondary'}>
                    {dates[1]}
                </Text>
            </div>
        </div>
    );
};
