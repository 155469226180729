import React from 'react';
import {useNavigate} from 'react-router-dom';
import block from 'bem-cn-lite';
import {LevelPrice} from '@features/profile';

import './LevelPricePage.scss';

const b = block('level-price-page');

export const LevelPricePage: React.FC = () => {
    return (
        <div className={b()}>
            <LevelPrice />
        </div>
    );
};
