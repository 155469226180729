import React, {useState} from 'react';
import block from 'bem-cn-lite';
import {Loader, Text} from '@gravity-ui/uikit';
import {OrdersEmpty, OrdersHeaderControls, OrdersItems} from '@features/orders';
import {useOrders} from '@features/orders/hooks';
import {ScrollUpButton} from '@components';
import {defaultDirection, defaultSortPriority} from '@features/orders/utils/sortUtils';
import {useSortedItems} from '@features/orders/hooks/useSortedItems';
import StickyBox from 'react-sticky-box';

import './OrdersPage.scss';

const b = block('orders-page');
const stickyElementsTopOffset = 127;

export const OrdersPage = () => {
    const {originalItems, items, filters, setFilters, isLoading, isFiltered} = useOrders();
    const [hiddenScrollBtn, setHiddenScrollBtn] = useState<boolean>(true);

    const [sortPriority, setSortPriority] = useState<string[]>(defaultSortPriority);

    const [sortOption, setSortOption] = useState<{
        property: string;
        direction: 'asc' | 'desc';
    } | null>(null);

    const handleSortChange = (field: string) => {
        setSortOption((prevSortOption) => {
            if (prevSortOption && prevSortOption.property === field) {
                return {
                    property: field,
                    direction: prevSortOption.direction === 'asc' ? 'desc' : 'asc'
                };
            } else {
                const direction = defaultDirection.get(field) === 1 ? 'asc' : 'desc';
                return {
                    property: field,
                    direction: direction as 'asc' | 'desc'
                };
            }
        });

        setSortPriority((prevSortPriority) => {
            const newSortPriority = prevSortPriority.filter((item) => item !== field);
            newSortPriority.unshift(field);
            return newSortPriority;
        });
    };

    const sortedItems = useSortedItems(sortOption, sortPriority);

    if (isLoading) {
        return (
            <div className={b('loader')}>
                <Loader size="l" />
            </div>
        );
    }

    // Нет заказов
    if (originalItems.length === 0) {
        return (
            <div className={b()}>
                <Text variant={'header-2'}>Заказы</Text>
                <OrdersEmpty isNoOrders={true} />
            </div>
        );
    }

    return (
        <div className={b()}>
            <div className={b('header-background')}></div>

            <StickyBox
                className={b('orders-header-controls-sticky-box')}
                offsetTop={stickyElementsTopOffset}
            >
                <OrdersHeaderControls filters={filters} setFilters={setFilters} />
            </StickyBox>

            {isFiltered ? (
                items.length === 0 ? (
                    <OrdersEmpty isNoOrders={false} />
                ) : (
                    <OrdersItems
                        items={sortedItems.filter((item) => item)}
                        onSortChange={handleSortChange}
                        sortOption={sortOption}
                    />
                )
            ) : (
                <OrdersItems
                    items={sortedItems.filter((item) => item)}
                    onSortChange={handleSortChange}
                    sortOption={sortOption}
                />
            )}

            <ScrollUpButton hiddenBtn={hiddenScrollBtn} changeVisibility={setHiddenScrollBtn} />
        </div>
    );
};
