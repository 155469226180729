export function getNounForm(number: number, forms: string[]) {
    /*
    * например:
    * forms = ['предложение', 'предложения', 'предложений'];
    */
    const mod100 = number % 100;
    if (mod100 >= 11 && mod100 <= 19)
        return forms[2]; // 11-19 предложений

    const mod10 = number % 10;
    if (mod10 === 1)
        return forms[0]; // 1 предложение
    if (mod10 > 1 && mod10 < 5)
        return forms[1]; // 2-4 предложения
    return forms[2]; // 0, 5-9 предложений
}
