import React, {useState} from 'react';
import block from 'bem-cn-lite';
import {Item, SectionHeader, SectionItems} from './components';
import {SectionItemModel} from '@features/search/interfaces';
import './Section.scss';

const b = block('section');

interface SectionProps {
    title: string;
    items: SectionItemModel[];
    isTopLevel?: boolean;
}

export const Section: React.FC<SectionProps> = ({
    title,
    items,
    isTopLevel = false,
}) => {
    const [isHidden, setIsHidden] = useState(false);

    function toggleVisibility() {
        setIsHidden((prev) => !prev);
    }

    return isTopLevel || items.length > 0 ?
        (<div className={b({hidden: isHidden})}>
            <SectionHeader
                title={title}
                isHidden={isHidden}
                onToggleVisibility={toggleVisibility}
                isTopLevel={isTopLevel}
            />
            {items.length > 0 ?
                <SectionItems hiddenSection={isHidden}>
                    {items.map((item) => {
                        return (
                            <Item key={item.key} header={item.header} proposals={item.proposals}></Item>
                        );
                    })}
                </SectionItems> : <div className={b('not-found', {visibility: isHidden})}>По вашему запросу ничего не нашлось.</div>}
        </div>) : null;
};