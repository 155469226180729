import React, {useState} from 'react';
import block from 'bem-cn-lite';
import {Button, Text, Tooltip} from '@gravity-ui/uikit';
import {Envelope, Handset, MapPin} from '@gravity-ui/icons';

import './FooterBlock.scss';

const b = block('footer-block');

interface ContactProps {
    children: React.ReactNode;
    copyText: string;
}

interface FooterBlockProps {
    isMinimal?: boolean;
}

const Contact: React.FC<ContactProps> = ({children, copyText}) => {
    const [openTooltip, setOpenTooltip] = useState(false);

    return (
        <Tooltip
            content="Скопировано"
            placement="top-end"
            disabled={!openTooltip}
            openDelay={0}
            contentClassName={b('tooltip')}
            className={b('tooltip-container')}
        >
            <div
                className={b('copy')}
                onClick={() => {
                    setOpenTooltip(true);
                    navigator.clipboard.writeText(copyText);
                    setTimeout(() => setOpenTooltip(false), 1000);
                }}
            >
                {children}
            </div>
        </Tooltip>
    );
};

export const FooterBlock: React.FC<FooterBlockProps> = ({isMinimal = false}) => {
    const currentYear = new Date().getFullYear();

    if (isMinimal) {
        return (
            <div className={b('footer-minimal')}>
                <Text variant="subheader-2">
                    ООО «Авто-Транзит»
                </Text>
                <Text variant="subheader-2">
                    © {currentYear}
                </Text>
            </div>
        );
    }

    return (

        <footer className={b('container')}>
            <div className={b('column-1')}>
                <Text variant="subheader-2">
                    ООО «Авто-Транзит»
                </Text>
                <Text variant="body-short">
                    ИНН 12345678910
                </Text>
                <Text variant="body-short">
                    ОГРН 12345678910
                </Text>
            </div>
            <div className={b('block-contact')}>
                <div className={b('column-2')}>
                    <Text variant="subheader-2">Связаться с нами:</Text>
                    <Button view='flat' size='m'>
                        <Handset className={b('icon')} />
                        <Contact copyText="+7 (383) 375-16-62">
                            +7 (383) 375-16-62
                        </Contact>
                    </Button>
                    <Button view='flat' size='m'>
                        <Handset className={b('icon', 'icon-hidden')} />
                        <Contact copyText="+7 (383) 375-16-64">
                            +7 (383) 375-16-64
                        </Contact>
                    </Button>
                </div>
                <div className={b('column-3')}>
                    <Button view='flat' size='m'>
                        <Envelope className={b('icon')} />
                        <Contact copyText="info@zapnsk.ru">avtoem@zapnsk.ru</Contact>
                    </Button>
                    <Button view='flat' size='m'>
                        <MapPin className={b('icon')} />
                        <Contact copyText="Новосибирск, ул. Оловозаводская, 47">Новосибирск, ул. Оловозаводская, 47</Contact>
                    </Button>
                </div>
            </div>
        </footer>
    );
};
