import {ProposalModel, SectionItemModel, SortOption, SortProperty} from '@features/search/interfaces';

export function getSorted(collection: SectionItemModel[], sortOption: (SortOption | null)) {

    if (!sortOption) return collection;

    const newCollection: SectionItemModel[] = [];

    for (const item of collection) {
        const newItem = {...item};
        newItem.proposals.sort((a, b) => {
            return compareByPriority(a, b, sortOption);
        });
        newCollection.push(newItem);
    }

    return newCollection;
}

function compareByPriority(a: ProposalModel, b: ProposalModel, sortOption: SortOption) {
    // Приоритетность сортировки по умолчанию
    let propertiesBySortPriority = ['price', 'deliveryDays', 'rating', 'quantity'];
    // Определяем выбранную сортировку и переставляем её в начало
    const sortProperty = getPropertyNameForSort(sortOption.property);
    propertiesBySortPriority = propertiesBySortPriority.filter(item => item !== sortProperty);
    propertiesBySortPriority.unshift(sortProperty);

    // Сортировки по умолчанию, которую будет использовать для дополнительных этапов сортировки
    const defaultDirection = new Map<string, number>();
    defaultDirection.set('price', 1);
    defaultDirection.set('deliveryDays', 1);
    defaultDirection.set('rating', -1);
    defaultDirection.set('quantity', -1);

    let c = 0;

    for (const property of propertiesBySortPriority) {
        if (isNumber(a[property]) && isNumber(b[property])) {
            c = a[property] - b[property];
        }

        // если элементы отличаются
        if (c !== 0) {
            // если сортируем по основному полю, то используем переданный порядок сортировки
            if (sortProperty === property) {
                if (sortOption.direction === 'desc')
                    c *= -1;
            }
            else { // если сортируем по дополнительному полю, то используем порядок сортировки по умолчанию
                c *= (defaultDirection.get(property) || 1);
            }
            break;
        }
    }

    return c;
}

function isNumber(value: any) {
    return typeof value === 'number';
}

function getPropertyNameForSort(sortProperty: SortProperty) {
    if (sortProperty === SortProperty.Price)
        return 'price';
    if (sortProperty === SortProperty.DeliveryDate || sortProperty === SortProperty.DeliveryDays)
        return 'deliveryDays';
    if (sortProperty === SortProperty.Quantity)
        return 'quantity';
    if (sortProperty === SortProperty.Rating)
        return 'rating';
    throw `Unknown sort property: ${sortProperty}`;
}