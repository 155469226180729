import React, {useState} from 'react';
import block from 'bem-cn-lite';
import {Checkbox, Text as GText} from '@gravity-ui/uikit';
import {CaretDown, CaretUp, ChevronDown} from '@gravity-ui/icons';

import './BrandFilter.scss';
import {FilterBrandModel} from '@features/search/interfaces';
import {formatCurrencyRub} from '@utils';

const b = block('brand-filter');

const MAX_BRANDS_TO_SHOW = 5;

interface BrandSortOption {
    direction: 'asc' | 'desc';
    isActive: boolean;
    property: 'name' | 'price';
    defaultDirection: 'asc' | 'desc';
}

export interface BrandFilterProps {
    brands: FilterBrandModel[];
    onBrandsChange?: (brands: FilterBrandModel[]) => void;
}

export const BrandFilter: React.FC<BrandFilterProps> = ({brands, onBrandsChange}) => {
    const isShowMoreVisible = brands.length > MAX_BRANDS_TO_SHOW;

    const [showMoreToggled, setShowMoreToggled] = useState(false);
    const [brandSortOptions, setBrandSortOptions] = useState<BrandSortOption[]>([
        {
            direction: 'asc',
            isActive: true,
            property: 'name',
            defaultDirection: 'asc'
        },
        {
            direction: 'asc',
            isActive: false,
            property: 'price',
            defaultDirection: 'asc'
        }
    ]);
    const nameSort = brandSortOptions.filter((x) => x.property === 'name')[0];
    const priceSort = brandSortOptions.filter((x) => x.property === 'price')[0];

    const activeSorts = brandSortOptions.filter((x) => x.isActive);
    const activeSort = activeSorts.length === 1 ? activeSorts[0] : brandSortOptions[0];

    const sortedBrands = brands.slice().sort((a, b) => {
        if (activeSort.property === 'name')
            return activeSort.direction === 'asc'
                ? a.brandName.localeCompare(b.brandName)
                : b.brandName.localeCompare(a.brandName);
        else if (activeSort.property === 'price')
            return activeSort.direction === 'asc'
                ? a.minPrice - b.minPrice
                : b.minPrice - a.minPrice;
        return 0;
    });
    const brandsToShow = (sortedBrands.length <= MAX_BRANDS_TO_SHOW) || showMoreToggled
        ? sortedBrands
        : sortedBrands.slice(0, MAX_BRANDS_TO_SHOW);

    function onBrandSelectedChange(brandName: string, value: boolean) {
        let brandsWithCheck = brands.map((brand) => (brand.brandName === brandName ? {
            ...brand,
            isSelected: value
        } : brand));
        if (onBrandsChange) {
            onBrandsChange(brandsWithCheck);
        }
    }

    function handleSortClick(sortOption: BrandSortOption) {
        const getSortDirection = (so: BrandSortOption) => {
            return !so.isActive ? so.defaultDirection : so.direction === 'asc' ? 'desc' : 'asc';
        };
        const newSortOptions = brandSortOptions.map((x) => {
            return x.property !== sortOption.property
                ? {...x, direction: 'asc', isActive: false}
                : {...x, direction: getSortDirection(sortOption), isActive: true};
        }) as BrandSortOption[];
        setBrandSortOptions(newSortOptions);
    }

    function handleShowMoreClick() {
        setShowMoreToggled((prev) => !prev);
    }

    return (
        <div className={b()}>
            <div className={b('header')}>
                <div className={b('header-column')} onClick={() => handleSortClick(nameSort)}>
                    <GText>Бренд</GText>
                    <div className={b('header-sorter')}>
                        {nameSort.direction === 'asc' ? (
                            <CaretUp className={b('caret', {active: nameSort.isActive})} />
                        ) : (
                            <CaretDown className={b('caret', {active: nameSort.isActive})} />
                        )}
                    </div>
                </div>
                <div style={{flexGrow: 1}}></div>
                <div className={b('header-column')} onClick={() => handleSortClick(priceSort)}>
                    <GText>Цена</GText>
                    <div className={b('header-sorter')}>

                        {priceSort.direction === 'asc' ? (
                            <CaretUp className={b('caret', {active: priceSort.isActive})} />
                        ) : (
                            <CaretDown className={b('caret', {active: priceSort.isActive})} />
                        )}
                    </div>
                </div>
            </div>
            <div className={b('brand-list')}>
                {brandsToShow.map((brand) => {
                    return (
                        <div key={brand.brandName} className={b('brand-row')}>
                            <Checkbox
                                className={b('brand-name')}
                                checked={brand.isSelected}
                                onChange={(e) =>
                                    onBrandSelectedChange(brand.brandName, e.target.checked)
                                }
                            >
                                {brand.brandName}
                            </Checkbox>
                            <GText className={b('brand-min-price')}>от&nbsp;{formatCurrencyRub(brand.minPrice)}</GText>
                        </div>
                    );
                })
                }
            </div>
            <div className={b('footer', {hidden: !isShowMoreVisible})} onClick={handleShowMoreClick}>
                <GText>{showMoreToggled ? 'Свернуть' : 'Показать все'}</GText>
                <ChevronDown className={b('icon', {rotation: showMoreToggled})}></ChevronDown>
            </div>
        </div>
    );
};