import React from 'react';
import {Items} from './Items/Items';
import {
    closestCorners,
    DndContext,
    DragEndEvent,
    MouseSensor,
    PointerSensor,
    TouchSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import {arrayMove, SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';

interface ListItemsProps {
    data: {id: number; text: string}[];
    setData: React.Dispatch<React.SetStateAction<{id: number; text: string}[]>>;
    itemsChecked: Record<number, boolean>;
    setItemsChecked: React.Dispatch<React.SetStateAction<Record<number, boolean>>>;
}

export const ListItems: React.FC<ListItemsProps> = ({
    data,
    setData,
    itemsChecked,
    setItemsChecked
}) => {
    const getPosition = (id: number) => {
        return data.findIndex((item) => item.id == id);
    };

    const handleDragEnd = (event: DragEndEvent) => {
        const {active, over} = event;
        if (active.id === over?.id) return;

        setData(() => {
            const originalPosition = getPosition(Number(active.id));
            const latestPosition = getPosition(Number(over?.id));

            return arrayMove(data, originalPosition, latestPosition);
        });
    };

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 3
            }
        }),
        useSensor(TouchSensor),
        useSensor(MouseSensor)
    );

    return (
        <div>
            <DndContext
                sensors={sensors}
                onDragEnd={handleDragEnd}
                collisionDetection={closestCorners}
            >
                <SortableContext items={data} strategy={verticalListSortingStrategy}>
                    {data.map((item) => (
                        <Items
                            key={item.id}
                            {...item}
                            isChecked={itemsChecked[item.id]}
                            toggleCheck={() => {
                                if (item.id !== 6) { // Проверяем, что это не "Наименование"
                                    setItemsChecked((prev) => ({
                                        ...prev,
                                        [item.id]: !prev[item.id],
                                    }));
                                }
                            }}
                        />
                    ))}
                </SortableContext>
            </DndContext>
        </div>
    );
};
