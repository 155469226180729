import {FilterRangeModel, FiltersModel, ProposalModel, SectionItemModel} from '@features/search/interfaces';
import {ArticleInfoModel} from '@components';

export function getFiltered(
    sourceCollection: SectionItemModel[],
    filters: FiltersModel,
) {
    const filteredCollection: SectionItemModel[] = [];

    for (const item of sourceCollection) {
        if (!isMatchHeader(item.header, filters)) continue;

        const sProposals: ProposalModel[] = [];
        for (const p of item.proposals) {
            if (isMatchProposal(p, filters)) sProposals.push(p);
        }

        if (sProposals.length > 0)
            filteredCollection.push({...item, proposals: sProposals});
    }

    return filteredCollection;
}

function isMatchHeader(header: ArticleInfoModel, filtersParam: FiltersModel) {
    const selectedBrands = filtersParam.brands.filter((x) => x.isSelected);
    if (selectedBrands.length > 0 && selectedBrands.length !== filtersParam.brands.length) {
        if (selectedBrands.filter((x) => x.brandName === header.brandName).length === 0)
            return false;
    }

    return true;
}

function isMatchProposal(proposal: ProposalModel, filtersParam: FiltersModel) {
    if (!isMatchRange(filtersParam.priceRange, proposal.price)) return false;
    if (!isMatchRange(filtersParam.deliveryDayRange, proposal.deliveryDays)) return false;
    if (!isMatchRange(filtersParam.ratingRange, proposal.rating)) return false;
    if (!isMatchRange(filtersParam.quantityRange, proposal.quantity)) return false;
    if (filtersParam.isReturnPossible && !proposal.isReturnPossible) return false;
    if (filtersParam.isOfficialDealer && !proposal.isOfficialDealer) return false;
    // TODO Понять, как работает Уровень цен

    return true;
}
function isMatchRange(range: FilterRangeModel, value: number) {
    if (range.from && value < range.from) return false;
    // так проще читается
    // noinspection RedundantIfStatementJS
    if (range.to && value > range.to) return false;
    return true;
}