import {useContext, useState} from 'react';
import {OrderPost} from '@services/user-gw';
import {CApi as userGwApi} from '@services/api';
import {useToast} from '@hooks';
import {AppContext} from 'app/context';

export const useCreateOrder = () => {
    const {state, emit} = useContext(AppContext);
    const {basket} = state;
    const {
        showToast,
        showCreatedOrderToast,
        showMakeOrderErrorToast,
        showBlockOrderToast,
        showNotEnoughFinanceOrderToast,
        showNoticeCreatedOrderToast
    } = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');

    const createOrder = async (): Promise<boolean> => {
        setIsLoading(true);
        setLoadingText('Оформляем заказ...');
        try {
            const basketItems: string[] = basket.items
                .filter((item) => item.checked)
                .map((item) => item.id);

            if (basketItems.length === 0) {
                showMakeOrderErrorToast();
                setIsLoading(false);
                return false;
            }

            const orderData: OrderPost = {
                basketItems
            };

            const response = await userGwApi().order.orderCreate(orderData);
            console.log('Заказ создан:', response);

            const updatedItems = basket.items.filter((item) => !item.checked);
            emit('updateBasket', {
                basket: {
                    items: updatedItems
                }
            });

            showCreatedOrderToast();
            return true;
        } catch (error) {
            console.error('Ошибка при создании заказа:', error);
            showToast('danger', 'Произошла ошибка при создании заказа');
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    return {createOrder, isLoading, loadingText};
};
