import React, {useEffect} from 'react';
import block from 'bem-cn-lite';
import './SectionItems.scss';

const b = block('section-items');

export interface SectionItemsProps {
    children: React.ReactNode;
    hiddenSection?: boolean
}

export const SectionItems: React.FC<SectionItemsProps> = (props) => {
    return <div className={b({hidden: props.hiddenSection ? true: false})}>{props.children}</div>;
};
