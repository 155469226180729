import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {DatePicker} from '@gravity-ui/date-components';
import {Button, Icon, Popup, Text} from '@gravity-ui/uikit';
import {FiltersModel} from '@features/orders/interfaces';
import {DateTime, dateTimeParse} from '@gravity-ui/date-utils';
import {Xmark, Calendar} from '@gravity-ui/icons';
import {usePopoverAuxControls} from '@hooks';

import './CustomRangeDatePicker.scss';

import block from 'bem-cn-lite';

const b = block('custom-range-date-picker');

interface CustomRangeDatePickerProps {
    filters: FiltersModel;
    setFilters: Dispatch<SetStateAction<FiltersModel>>;
    className?: string;
    rangeKey: 'createdAtRange' | 'deliveryDateRange';
}

export const CustomRangeDatePicker: React.FC<CustomRangeDatePickerProps> = ({
    filters,
    setFilters,
    className,
    rangeKey
}) => {
    const {open, buttonRef, toggleOpen, closePopover} = usePopoverAuxControls();
    const [startDate, setStartDate] = useState<DateTime | null>(filters[rangeKey]?.start || null);
    const [endDate, setEndDate] = useState<DateTime | null>(filters[rangeKey]?.end || null);

    const placeholder = rangeKey === 'createdAtRange' ? 'Дата заказа' : 'Дата доставки';

    useEffect(() => {
        if (!filters[rangeKey]) {
            setStartDate(null);
            setEndDate(null);
        }
    }, [filters[rangeKey]]);

    function handleStartDateChange(value: DateTime | null) {
        setStartDate(value);
        updateRange(value, endDate);
    }

    function handleEndDateChange(value: DateTime | null) {
        setEndDate(value);
        updateRange(startDate, value);
    }

    function updateRange(start: DateTime | null, end: DateTime | null) {
        const adjustedStart = start || undefined;
        const adjustedEnd = end ? end.endOf('day') : undefined;

        if (adjustedStart || adjustedEnd) {
            setFilters((prev) => ({
                ...prev,
                [rangeKey]: {start: adjustedStart, end: adjustedEnd}
            }));
        } else {
            setFilters((prev) => ({
                ...prev,
                [rangeKey]: null
            }));
        }
    }

    function setToday() {
        const today = dateTimeParse(new Date());
        if (today) {
            const startOfDay = today.startOf('day');
            const endOfDay = today.endOf('day');
            setStartDate(startOfDay);
            setEndDate(endOfDay);
            updateRange(startOfDay, endOfDay);
        }
    }

    function setCurrentWeek() {
        const today = dateTimeParse(new Date());
        if (today) {
            const startOfWeek = today.startOf('week');
            const endOfWeek = today.endOf('week');
            setStartDate(startOfWeek);
            setEndDate(endOfWeek);
            updateRange(startOfWeek, endOfWeek);
        }
    }

    function setCurrentMonth() {
        const today = dateTimeParse(new Date());
        if (today) {
            const startOfMonth = today.startOf('month');
            const endOfMonth = today.endOf('month');
            setStartDate(startOfMonth);
            setEndDate(endOfMonth);
            updateRange(startOfMonth, endOfMonth);
        }
    }

    function setLastMonth() {
        const today = dateTimeParse(new Date());
        if (today) {
            const startOfLastMonth = today.subtract(1, 'month').startOf('month');
            const endOfLastMonth = today.subtract(1, 'month').endOf('month');
            setStartDate(startOfLastMonth);
            setEndDate(endOfLastMonth);
            updateRange(startOfLastMonth, endOfLastMonth);
        }
    }

    function setNextMonth() {
        const today = dateTimeParse(new Date());
        if (today) {
            const startOfNextMonth = today.add(1, 'month').startOf('month');
            const endOfNextMonth = today.add(1, 'month').endOf('month');
            setStartDate(startOfNextMonth);
            setEndDate(endOfNextMonth);
            updateRange(startOfNextMonth, endOfNextMonth);
        }
    }

    function handleClearDates(event: React.MouseEvent) {
        event.stopPropagation();
        setStartDate(null);
        setEndDate(null);
        updateRange(null, null);
    }

    return (
        <div className={className}>
            <div ref={buttonRef} onClick={toggleOpen} className={b('date-container')}>
                <Text variant="body-1" color={startDate || endDate ? 'primary' : 'hint'}>
                    {startDate || endDate ? (
                        <>
                            {startDate ? startDate.format('DD.MM.YYYY') : ''}
                            {startDate && endDate ? ' — ' : startDate ? ' —' : '— '}
                            {endDate ? endDate.format('DD.MM.YYYY') : ''}
                        </>
                    ) : (
                        placeholder
                    )}
                </Text>

                <div className={b('icon-container')}>
                    {(startDate || endDate) && (
                        <div onClick={handleClearDates}>
                            <Icon data={Xmark} className={b('clear-icon')} />
                        </div>
                    )}

                    <Icon data={Calendar} className={b('calendar-icon')} />
                </div>
            </div>

            <Popup anchorRef={buttonRef} onClose={closePopover} open={open} placement="bottom">
                <div className={b('popup-container')}>
                    <div className={b('date-picker-container')}>
                        <DatePicker
                            size="m"
                            hasClear
                            placeholder="ДД.MM.ГГГГ"
                            label="С:"
                            value={startDate}
                            onUpdate={handleStartDateChange}
                            format="DD.MM.YYYY"
                        />

                        <DatePicker
                            size="m"
                            hasClear
                            placeholder="ДД.MM.ГГГГ"
                            label="До:"
                            value={endDate}
                            onUpdate={handleEndDateChange}
                            format="DD.MM.YYYY"
                        />

                        <Button className={b('button')} size="m" onClick={closePopover}>
                            Применить
                        </Button>
                    </div>

                    <div className={b('presets-container')}>
                        <Text variant="body-1" className={b('preset-item')} onClick={setToday}>
                            Сегодня
                        </Text>
                        <Text
                            variant="body-1"
                            className={b('preset-item')}
                            onClick={setCurrentWeek}
                        >
                            Текущая неделя
                        </Text>
                        <Text
                            variant="body-1"
                            className={b('preset-item')}
                            onClick={setCurrentMonth}
                        >
                            Текущий месяц
                        </Text>
                        <Text variant="body-1" className={b('preset-item')} onClick={setLastMonth}>
                            Прошлый месяц
                        </Text>
                        <Text variant="body-1" className={b('preset-item')} onClick={setNextMonth}>
                            Следующий месяц
                        </Text>
                    </div>
                </div>
            </Popup>
        </div>
    );
};
